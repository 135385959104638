/**
 * 揭榜挂帅Vuex-actions
 */
export default {
  saveUserName(context, username) {
    context.commit("saveUserName", username);
  },
  saveHeaderFlag(context, headerFlag) {
    context.commit("saveHeaderFlag", headerFlag);
  },
  saveFooterFlag(context, footerFlag) {
    context.commit("saveFooterFlag", footerFlag);
  },
  saveToken(context, token) {
    context.commit("saveToken", token);
  },
  saveShowZw(context, showZw) {
    context.commit("saveShowZw", showZw);
  },
  saveUnreadCount(context, unreadCount){
    context.commit("saveUnreadCount", unreadCount);
  },
  saveLanguage(context, language){
    context.commit("saveLanguage", language);
  },
};
