import request from "@/utils/request";

//查询消息列表
export function myMessage(query){
    return request({
        url: "/commander/message/query/message/list",
        method: "get",
        params: query,
    });
}

//查询消息列表
export function read(data){
    return request({
        url: "/commander/message/read/message?id="+data,
        method: "post",
    });
}