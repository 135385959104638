<template>
    <div>
        <transition name="message-box">
            <div v-if="showMessagePush" class="message-box  messages-genkg">

                <el-table :data="messageList" @expand-change="expandChange" :expand-row-keys="expands"
                    :row-key='getRowKeys' :row-style="rowstyle">
                    <div class="genkg-table">
                    <el-table-column type="expand">
                        <template slot-scope="props">
                            <el-form label-position="left" inline class="demo-table-expand">
                                <el-form-item :label="$t('common.listType')">
                                    <span>{{ props.row.listType | listTypeQuery }}</span>
                                </el-form-item>
                                <el-form-item label="信息类型">
                                    <span>{{ props.row.messageType | messageTypeQuery }}</span>
                                </el-form-item>
                                <el-form-item label="榜单标题">
                                    <span>{{ props.row.messageTitle }}</span>
                                </el-form-item>
                                <el-form-item label="创建时间">
                                    <span>{{ props.row.createTime | dateFormat }}</span>
                                </el-form-item>
                                <el-form-item label="读取状态">
                                    <span>{{ props.row.readType | readTypeQuery }}</span>
                                </el-form-item>
                            </el-form>
                        </template>
                    </el-table-column>
                     </div>
                    <el-table-column prop="messageTitle" label="消息标题" >
                    </el-table-column>
                </el-table>
                <div class="message-item" v-if="!messageList || messageList.length <= 0" style="cursor: default">
                    <div class="no-message">暂无消息！</div>
                </div>
                <div class="messages-page">
                <el-pagination :page-size="pagination.size" :pager-count="4"
                :current-page="pagination.page" background small
                    layout="prev, pager, next"
                    :total="pagination.total"
                     @current-change="handleCurrentChange"></el-pagination>
                 </div>

            </div>
        </transition>

    </div>
</template>

<script>
import { myMessage, read } from "@/api/message"

export default {
    computed: {
        userToken() {
            return localStorage.getItem("userToken")
        },
        showMessagePush() {
            return this.$store.state.showMessagePush;
        },
        unreadCount() {
            return this.$store.state.unreadCount;
        }
    },
    props: {},
    watch: {
        "showMessagePush": function (event) {
            if (this.showMessagePush) {
                this.queryList();
            }
        },
        "unreadCount": function (event) {
            //刷新
            if (this.showMessagePush) {
                this.queryList();
            }
        }


    },
    filters: {
        listTypeQuery(index) {
            var listTypeList = [{
                key: "0",
                value: "政府榜单"
            },
            {
                key: "1",
                value: "需求榜单"
            }, {
                key: "2",
                value: "成果榜单"
            },
            {
                key: "9",
                value: "政府榜单"
            },
            {
                key: "10",
                value: "企业榜单奖榜"
            }]
            for (let i = 0; i < listTypeList.length; i++) {
                if (listTypeList[i].key == index) {
                    return listTypeList[i].value
                }
            }
        },
        messageTypeQuery(index) {
            var messageTypeList = [{
                key: "0",
                value: "默认消息"
            },
            {
                key: "1",
                value: "榜单推送消息"
            }, {
                key: "2",
                value: "联系消息"
            },
            ]
            for (let i = 0; i < messageTypeList.length; i++) {
                if (messageTypeList[i].key == index) {
                    return messageTypeList[i].value
                }
            }
        },
        readTypeQuery(index) {
            var readTypeList = [{
                key: "0",
                value: "未读"
            },
            {
                key: "1",
                value: "已读"
            }
            ]
            for (let i = 0; i < readTypeList.length; i++) {
                if (readTypeList[i].key == index) {
                    return readTypeList[i].value
                }
            }
        },



    },

    data: function () {
        return {
            // chatList: new LinkedList(),
            messageList: [],
            srcUrl: process.env.VUE_APP_IMAGE_URL + "/front/oss/download/",
            uniNow: {},
            pagination: {
                page: 1,
                size: 5,
                total: 0
            },
            //设置row-key只展示一行
            expands: [],//只展开一行放入当前行id
            getRowKeys(row) {
                return row.id
            },
            friendUserNow: {}
        }
    },

    mounted: function () {
        if (this.userToken) {
            this.queryList();
        }
    },
    created() {
    },
    methods: {
        queryList() {
            let that = this
            myMessage({
          page: that.pagination.page,
          pageSize: that.pagination.size,
        }).then(res => {
                that.messageList = res.records
                that.pagination.total = res.total
            })
        },

        //表格当前行被展开或收起
        expandChange(row, expandedRows) {
            let that = this
            console.log(expandedRows);
            //只展开一行
            if (expandedRows.length) {//说明展开了
                that.expands = []

                if (row) {
                    that.expands.push(row.id)//只展开当前行id
                    if (row.readType == 0) {
                        read(row.id)
                    }

                }
            } else {//说明收起了
                that.expands = []
                if (row.readType == 0) {
                     this.queryList();
                    }

            }
        },
    //当前页值修改
    handleCurrentChange(currentPage) {
      this.pagination.page = currentPage;
      this.queryList();
    },

    rowstyle({ row, rowIndex }) {
            console.log(row, rowIndex);
            let stylejson = {};
            if (row.readType == "0") {
                // stylejson.background = "#e0838f";// 背景颜色
           // 也可以修改文字颜色
                stylejson.color='#000' ;
                return stylejson;
            } else {
                return "";
            }
        },
    }
}
</script>

<style scoped lang="less">
.message-box {
    background-color: white;
    z-index: 999;
    height: auto;
    max-height: 450px;
    width: 350px;
    top: 40px;
    right: 100px;
    overflow: auto;
    position: absolute;
    border-radius: 15px
}

::-webkit-scrollbar {
    width: 4px;
    height: 1px;
    right: 100px;
}

::-webkit-scrollbar-thumb {
    border-radius: 10px;
    box-shadow: inset 0 0 5px rgba(97, 184, 179, 0.1);
    background: #78b4b4;
}

::-webkit-scrollbar-track {
    border-radius: 10px;
    box-shadow: inset 0 0 5px rgba(87, 175, 187, 0.1);
    background: #ededed;
}


.message-box .message-item {
    display: flex;
    flex-direction: column;
    cursor: pointer;
    padding: 20px;
    /*border: 1px solid #e8e8e8;*/
    overflow: hidden;
    height: 110px;
    width: auto;
}


.message-item .message-pic {
    float: left;
    width: 60px;
    height: 60px;
    background-color: #f4f4f4;
    border-radius: 100%;
    overflow: hidden
}

.message-item .no-message {
    margin-left: 100px;
    margin-top: 24px;
    color: #aaaaaa;
    font-size: 15px;
}

.message-item .message-pic img {
    width: 100%;
    height: 100%;
}

.message-item .user-name {
    margin-left: 80px;
    margin-top: -55px;
    font-family: "Microsoft Yahei", '宋体', sans-serif;
    color: #1f8afe;
    font-size: 15px;
    text-overflow-ellipsis: ellipsis;
}

.message-item .listType {
    margin-left: 85px;
    margin-top: 10px;
    display: -webkit-box;
    overflow: hidden;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    color: grey;
}

.message-item .date {
    margin-left: 185px;
    margin-top: 10px;
    color: grey;
}

.message-item .num {
    font-family: tahoma;
    text-align: center;
    color: #fff;
    border-radius: 100%;
    height: 20px;
    width: 20px;
    background-color: red;
    margin-top: -30px;
    margin-left: 40px;
}


.message-box-enter-active {
    transition: all 0.3s ease;
}

.message-box-leave-active {
    transition: all 0.3s cubic-bezier(1, 0.5, 0.8, 1);
}

.message-box-enter,
.message-box-leave-to {
    opacity: 0;
}
.messages-genkg{
    height: auto;
    max-height: none;
    // overflow:auto;
    padding-bottom: 20px;
}

</style>
<style lang="less">
.messages-genkg{
    .el-table .cell{
        height: 23px;
    }
    .el-table__body-wrapper{
        min-height: 220px;
    }
    .el-form{
        padding: 0 20px;
    }
    .el-form-item{
        margin-bottom: 0;
        display: flex;
        margin-right: 0;
        .el-form-item__label{
            color: #999;
            line-height: 26px
        }
        .el-form-item__content{
            line-height: 26px;
            max-width: 230px;
            white-space:nowrap;
            overflow:hidden;
            text-overflow:ellipsis;
        }
    }
    .messages-page{
        padding-top: 15px;
        display: flex;
        justify-content: center;

    }

}
</style>
