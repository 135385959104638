<template>
    <div>
        <transition name="message-box">
            <div v-if="showMessageBox" class="message-box">
                <div class="message-item" v-for="(item,index) in chatList" :key="index" @click="openChatBox(item)">
                    <div class="message-pic">
                        <img v-lazy="{src:srcUrl + item.avatar,error:require('@public/pc/images/messbrd-head01.png')}"
                             alt/>
                    </div>
                    <div class="user-name">{{ item.friendUserName}}</div>
                    <div class="content">{{item.finalContent}}</div>
                    <div class="date">{{item.lastestTime}}</div>
                    <span class="num">{{item.notReadCount}}</span>
                </div>

                <div class="message-item" v-if="!chatList || chatList.length <= 0" style="cursor: default">
                    <div class="no-message">{{$t('common.noData')}}</div>
                </div>
            </div>
        </transition>

        <chat-box
                v-if="showChatBox"
                :uni-id="uniNow.uniId"
                refs="chatBox"
                @closeChat="closeChatBox"
                :friendUser="friendUserNow"
        ></chat-box>

    </div>
</template>

<script>
    import {groupChatInfo, createRelation} from "@/api/community"
    import chatBox from "@components/pc/chatBox";

    export default {
        components: {chatBox},
        name: "messageBox",
        props: {},
        watch: {
            "showMessageBox": function (event) {
                if (this.showMessageBox) {
                    this.queryList();
                }
            },
            "unreadCount": function (event) {
                //刷新
                if (this.showMessageBox) {
                    this.queryList();
                }
            }


        },

        computed: {
            userToken() {
                return localStorage.getItem("userToken")
            },
            showMessageBox() {
                return this.$store.state.showMessageBox;
            },
            unreadCount() {
                return this.$store.state.unreadCount;
            }
        },
        data: function () {
            return {
                // chatList: new LinkedList(),
                chatList: [],
                srcUrl: process.env.VUE_APP_IMAGE_URL + "/front/oss/download/",
                showChatBox: false,
                uniNow: {},
                friendUserNow: {}
            }
        },
        mounted: function () {
            if (this.userToken) {
                this.queryList();
            }
        },
        created() {
        },
        methods: {
            queryList() {
                let that = this
                groupChatInfo().then(res => {
                    console.log(res)
                    that.chatList = res
                })
            },
            openChatBox(item) {
                let _this = this;
                //获取存储在localStorage中的userToken
                let userToken = localStorage.getItem("userToken");
                //若有token数据 查询当前用户信息
                if (!userToken) {
                    return _this.$login.install({fromPath: _this.$route.fullPath});
                }
                let userId = item.friendUserId;
                createRelation({friendUserId: userId}).then(res => {
                    _this.uniNow = res;
                    _this.friendUserNow = {
                        userName: res.friendUserName,
                        userId: userId,
                        avatar: _this.srcUrl + res.friendAvatar
                    };
                    _this.showChatBox = true;
                });

            },
            closeChatBox() {
                this.showChatBox = false;
            }


        }


    }
</script>

<style scoped>
    .message-box {
        background-color: white;
        z-index: 999;
        height: auto;
        max-height: 450px;
        width: 350px;
        top: 40px;
        right: 100px;
        overflow: auto;
        position: absolute;
        border-radius: 15px
    }

    ::-webkit-scrollbar {
        width: 4px;
        height: 1px;
        right: 100px;
    }

    ::-webkit-scrollbar-thumb {
        border-radius: 10px;
        box-shadow: inset 0 0 5px rgba(97, 184, 179, 0.1);
        background: #78b4b4;
    }

    ::-webkit-scrollbar-track {
        border-radius: 10px;
        box-shadow: inset 0 0 5px rgba(87, 175, 187, 0.1);
        background: #ededed;
    }


    .message-box .message-item {
        display: flex;
        flex-direction: column;
        cursor: pointer;
        padding: 20px;
        /*border: 1px solid #e8e8e8;*/
        overflow: hidden;
        height: 110px;
        width: auto;
    }


    .message-item .message-pic {
        float: left;
        width: 60px;
        height: 60px;
        background-color: #f4f4f4;
        border-radius: 100%;
        overflow: hidden
    }

    .message-item .no-message {
        margin-left: 100px;
        margin-top: 24px;
        color: #aaaaaa;
        font-size: 15px;
    }

    .message-item .message-pic img {
        width: 100%;
        height: 100%;
    }

    .message-item .user-name {
        margin-left: 80px;
        margin-top: -55px;
        font-family: "Microsoft Yahei", '宋体', sans-serif;
        color: #1f8afe;
        font-size: 15px;
        text-overflow-ellipsis: ellipsis;
    }

    .message-item .content {
        margin-left: 85px;
        margin-top: 10px;
        display: -webkit-box;
        overflow: hidden;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 1;
        color: grey;
    }

    .message-item .date {
        margin-left: 185px;
        margin-top: 10px;
        color: grey;
    }

    .message-item .num {
        font-family: tahoma;
        text-align: center;
        color: #fff;
        border-radius: 100%;
        height: 20px;
        width: 20px;
        background-color: red;
        margin-top: -30px;
        margin-left: 40px;
    }


    .message-box-enter-active {
        transition: all 0.3s ease;
    }

    .message-box-leave-active {
        transition: all 0.3s cubic-bezier(1, 0.5, 0.8, 1);
    }

    .message-box-enter,
    .message-box-leave-to {
        opacity: 0;
    }


</style>
