/**
 * 揭榜挂帅Vuex-mutations
 */
export default {
  saveUserName(state, username) {
    state.username = username;
  },
  saveHeaderFlag(state, headerFlag) {
    state.headerFlag = headerFlag;
  },
  saveFooterFlag(state, footerFlag) {
    state.footerFlag = footerFlag;
  },
  saveToken(state, token) {
    state.token = token;
  },
  saveShowZw(state, showZw) {
    state.showZw = showZw;
  },
  saveUnreadCount(state, unreadCount){
    state.unreadCount = unreadCount;
  },
  saveLanguage(state, language){
    state.language = language;
  },
};
