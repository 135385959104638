import request from "@/utils/request";

//分页查询服务联盟列表数据
export function queryList(data) {
  return request({
    url: "/commander/alliance/list?page="+data.page+"&pageSize="+data.pageSize,
    method: "post",
    data,
  });
}

export function queryAllianceList(data) {
  return request({
    url: "/commander/alliance/list?page="+data.page+"&pageSize="+data.pageSize,
    method: "post",
    data,
  });
}

export function queryEsList(data) {
  return request({
    url: "/commander/alliance/es/list",
    method: "post",
    data,
  });
}

export function queryAlliance(data) {
  return request({
    url: "/commander/alliance/pc/list",
    method: "post",
    data,
  });
}

export function queryDetail(query) {
  return request({
    url: "/commander/alliance/queryDetail",
    method: "get",
    params: query,
  });
}
