import request from "@/utils/request";

//获取首页榜单统计数据
export function indexStatisticData(query) {
  return request({
    url: "/commander/index/list/statistics",
    method: "get",
    params: query,
  });
}

//获取首页推荐数据
export function indexRecommend(query) {
  return request({
    url: "/commander/technical/query/recommend",
    method: "get",
    params: query,
  });
}

//获取首页榜单数据
export function indexList(query) {
  return request({
    url: "/commander/index/query/index/list",
    method: "get",
    params: query,
  });
}


//获取首页兑榜文案数据
export function matchList() {
  return request({
    url: "/commander/index/query/match/list",
    method: "get",
  });
}