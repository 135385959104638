import Vue from 'vue';
import VueI18n from 'vue-i18n';
import enLocale from './language-EN'
import zhLocale from './language-CN'
import enLocaleEl from 'element-ui/lib/locale/lang/en'
import zhLocaleEl from 'element-ui/lib/locale/lang/zh-CN'
import ElementLocale from 'element-ui/lib/locale'
Vue.use(VueI18n);
const messages = {
    zh: {
        ...zhLocaleEl,
        ...zhLocale
    },
    en: {
        ...enLocaleEl,
        ...enLocale
    }
}
const i18n = new VueI18n({
    locale: localStorage.getItem('lang') || 'zh', // 通过this.$i18n.locale的值实现语言切换
    messages,
});

ElementLocale.i18n((key, value) => i18n.t(key, value))

export default i18n;