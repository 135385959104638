<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
import storage from "@/storage/storage";
import { mapActions } from "vuex";
import { getUnreadCount } from "@/api/community";
import { Queue } from "data-struct-js";
import { userInfo, detectOS } from "@/api/user";
const { Base64 } = require("js-base64");
export default {
  name: "root",
  data() {
    return {
      screenWidth: ""
    };
  },
  methods: {
    ...mapActions(["saveHeaderFlag", "saveUserName"]),
    //获取当前用户信息
    getCurrentUserInfo() {
      //获取用户信息
      userInfo().then(res => {
        //存入vuex中
        this.$store.dispatch("saveUserName", res.username);
        localStorage.setItem("userType", res.userType);
        let userToken = localStorage.getItem("userToken");
        this.$store.dispatch("saveToken", userToken);
      });
    },
    initRem() {
      const cale =
        window.screen.availWidth > 750 ? 2 : window.screen.availWidth / 375;
      window.document.documentElement.style.fontSize = `${50 * cale}px`;
    },
    oloRem() {
      const cale =
        window.screen.availWidth > 750 ? 2 : window.screen.availWidth / 375;
      window.document.documentElement.style.fontSize = `${70 * cale}px`;
    }
  },
  created() {
    let that = this;
    let language = storage.getItem("lang");
    if (!language) {
      language = "zh"
    }
    this.$store.dispatch("saveLanguage", language);
      this.$i18n.locale = language;
    //在页面加载时读取sessionStorage里的状态信息
    let headerFlag = storage.getItem("headerFlag");
    if (headerFlag) {
      this.$store.dispatch("saveHeaderFlag", headerFlag);
    }
    let footerFlag = storage.getItem("footerFlag");
    if (footerFlag) {
      this.$store.dispatch("saveFooterFlag", footerFlag);
    }
    let showZw = storage.getItem("showZw");
    if (showZw && showZw == "true") {
      this.$store.dispatch("saveShowZw", true);
    } else {
      this.$store.dispatch("saveShowZw", false);
    }
    //在页面刷新时将vuex里的信息保存到sessionStorage里
    window.addEventListener("beforeunload", () => {
      storage.setItem("headerFlag", this.$store.state.headerFlag);
      storage.setItem("footerFlag", this.$store.state.footerFlag);
      storage.setItem("showZw", this.$store.state.showZw);
      storage.setItem("lang", this.$store.state.language);
    });
    let connect = () => {
      //建立webSocket
      let token = window.localStorage.getItem("userToken");
      token = "Bearer " + token;
      token = Base64.encode(token);
      if (token && token != "") {
        let socket = new WebSocket(process.env.VUE_APP_WEBSOCKET_URL, token);
        window.webSocket = socket;
        // 注册回调事件
        socket.onopen = function(e) {
          console.log("服务器连接成功");
          window.messageMapper = {};
        };
        socket.onclose = function(e) {
          if (e.code != 1000 && e.code != 1001) {
            //不是正常关闭和终端离开进行重连：
            connect();
          }
        };

        socket.onmessage = function(e) {
          let resData = JSON.parse(e.data);
          if (resData.uniId && resData.uniId != "") {
            if (!window.messageMapper[resData.uniId]) {
              window.messageMapper[resData.uniId] = new Queue();
            }
            //放入队列
            window.messageMapper[resData.uniId].enqueue(resData);
            if (!resData.isSelf) {
              //不是自己发的
              that.$store.state.unreadCount += 1;
            }
          }
          if (resData.subUnread == 1) {
            that.$store.state.unreadCount += -1;
          }
        };
      }
    };

    //监听localStorage的变化
    window.addEventListener("setItemEvent", function(e) {
      if (e.key === "userType" && window.WebSocket) {
        connect();
        //获取聊天未读数
        getUnreadCount().then(res => {
          that.$store.state.unreadCount = res;
        });
      }
    });
    //添加定时器
    const timer = setInterval(() => {
      if (window.webSocket) {
        let params = {
          content: "beat package",
          uniId: "",
          contentType: "4"
        };
        window.webSocket.send(JSON.stringify(params));
        console.log("已发送心跳报文" + new Date().getSeconds());
      }
    }, 45000);
  },
  mounted() {
    //获取存储在localStorage中的userToken
    let userToken = localStorage.getItem("userToken");
    //若有token数据 查询当前用户信息
    if (userToken) {
      // return
      this.getCurrentUserInfo();
    }
    let systemBrowser = localStorage.getItem("systemBrowser");
    if(!systemBrowser){
      localStorage.setItem("systemBrowser", detectOS());
    }
    let zlbUiStyle = localStorage.getItem("zlbUiStyle");
    let _this = this;
    if (zlbUiStyle == "elder") {
      _this.oloRem();
      window.document.getElementsByTagName("body")[0].className =
        "older-version";
    } else {
      _this.initRem();
    }
  },
  watch: {
    $route: {
      handler: function(route) {
        let show = this.$store.state.showZw;
        if (show) {
          return;
        }
        let routeName = route.name;
        if (routeName == "pc-technology") {
          let showZw = Tool.getQueryVariable("showZw");
          if (showZw) {
            localStorage.setItem("showZw", showZw);
            this.$store.dispatch("saveShowZw", true);
            return;
          } else {
            localStorage.setItem("showZw", false);
            this.$store.dispatch("saveShowZw", false);
          }
        }
      },
      immediate: true
    }
  }
};
</script>
