import Vue from "vue";
import Router from "vue-router";
//pc
import PcIndex from "./views/pc/index/index.vue";

//mobile
import MbIndex from "./views/mobile/index/Home.vue";

Vue.use(Router);

//获取原型对象上的push函数
const originalPush = Router.prototype.push;
//修改原型对象中的push方法
Router.prototype.push = function push(location) {
    return originalPush.call(this, location).catch((err) => err);
};

//修改原型对象中的replace方法
const originalReplace = Router.prototype.replace;
Router.prototype.replace = function replace(location) {
    return originalReplace.call(this, location).catch((err) => err);
};

const redirectUrl = Tool.isMobile() ? "mb/index" : "pc/index";

export default new Router({
    mode: "history",
    base: process.env.BASE_URL,
    scrollBehavior(to, from, savedPosition) {
        // keep-alive 返回缓存页面后记录浏览位置
        if (savedPosition && to.meta.keepAlive) {
            return savedPosition;
        }
        // 异步滚动操作
        return new Promise((resolve) => {
            setTimeout(() => {
                resolve({ x: 0, y: 1 });
            }, 0);
        });
    },
    routes: [{
        path: "/",
        redirect: redirectUrl,
    },
        {
            path: "*",
            redirect: redirectUrl,
        },
        {
            path: "/pc/index",
            name: "pc-index",
            component: PcIndex,
            meta: {
                title: '揭榜挂帅'
            }
        },
        {
            path: "/loginLoading/*",
            name: "login-loading",
            component: () =>
                import("./views/loginLoading.vue"),
        },
        {
            path: "/pc/pre-registration-release",
            name: "pc-pre-registration-release",
            component: () =>
                import("./views/pc/pre/pre-registration-release.vue"),
            meta: {
                title: '科技类校外培训机构预登记'
            }
        },
        {
            path: "/pc/zdyq/expert/list",
            name: "pc-zdyq-expert-list",
            component: () =>
                import("./views/pc/expertZdyq/talent-expert.vue"),
            meta: {
                title: '招大引强-专家列表'
            }
        },
        {
            path: "/pc/zdyq/expert/detail",
            name: "pc-zdyq-expert-detail",
            component: () =>
                import("./views/pc/expertZdyq/expert-detail.vue"),
            meta: {
                title: '招大引强-专家列表'
            }
        },
        {
            path: "/pc/search",
            name: "pc-search",
            component: () =>
                import("./views/pc/search/search.vue"),
            meta: {
                title: '揭榜挂帅-网站信息统一搜索'
            }
        },
        {
            path: "/pc",
            name: "pc-business",
            component: () =>
                import("./views/pc/business.vue"),
            children:
                [
                    {
                        path: "result-release",
                        name: "pc-result-release",
                        component: () =>
                            import("./views/pc/result/result-release.vue"),
                        meta: {
                            title: '揭榜挂帅-成果鉴定申请'
                        }
                    },
                    {
                        path: "inputShow-release",
                        name: "pc-inputShow-release",
                        component: () =>
                            import("./views/pc/result/input-show-release.vue"),
                        meta: {
                            title: '揭榜挂帅-成果鉴定申请'
                        }
                    },
                    {
                        path: "talent",
                        name: "pc-talent",
                        component: () =>
                            import("./views/pc/personnel/talent.vue"),
                        meta: {
                            title: '揭榜挂帅-成果榜单'
                        }
                    },
                    {
                        path: "loan-detail",
                        name: "pc-loan-detail",
                        component: () =>
                            import("./views/pc/loan/loan-detail"),
                        meta: {
                            title: '揭榜挂帅-科技贷申请'
                        }
                    },
                    {
                        path: "talent-detail",
                        name: "pc-talent-detail",
                        component: () =>
                            import("./views/pc/personnel/talent-detail.vue"),
                        meta: {
                            title: '揭榜挂帅-成果榜单'
                        }
                    },
                    {
                        path: "talent-release-new",
                        name: "pc-talent-release-new",
                        component: () =>
                            import("./views/pc/personnel/talent-release-new.vue"),
                        meta: {
                            title: '揭榜挂帅-成果榜单发布页'
                        }
                    },
                    {
                        path: "talent-exhibition-release",
                        name: "pc-talent-exhibition-release",
                        component: () =>
                            import("./views/pc/result/talent-exhibition-release.vue"),
                        meta: {
                            title: '揭榜挂帅-企业成果发布'
                        }
                    },
                    {
                        path: "result-display",
                        name: "pc-result-display",
                        component: () =>
                            import("./views/pc/result/result-display-list.vue"),
                        meta: {
                            title: '揭榜挂帅-成果展厅'
                        }
                    },
                    {
                        path: "result-display-detail",
                        name: "pc-result-display-detail",
                        component: () =>
                            import("./views/pc/result/result-display-detail.vue"),
                        meta: {
                            title: '揭榜挂帅-成果展厅详情'
                        }
                    },

                    {
                        path: "input-show-detail",
                        name: "pc-input-show-detail",
                        component: () =>
                            import("./views/pc/result/input-show-detail.vue"),
                        meta: {
                            title: '揭榜挂帅-省创新馆详情'
                        }
                    },


                    {
                        path: "technology",
                        name: "pc-technology",
                        component: () =>
                            import("./views/pc/technology/technology.vue"),
                        meta: {
                            title: '揭榜挂帅-企业榜单',
                            keepAlive: true
                        }
                    },
                    {
                        path: "technologyYk",
                        name: "pc-technologyYk",
                        component: () =>
                            import("./views/pc/technology/technology.vue"),
                        meta: {
                            area: "330784",
                            title: "揭榜挂帅-永康企业榜单"
                        }
                    },
                    {
                        path: "technology-detail",
                        name: "pc-technology-detail",
                        component: () =>
                            import("./views/pc/technology/technology-detail.vue"),
                        meta: {
                            title: '揭榜挂帅-企业榜单详情'
                        }
                    },
                    {
                        path: "technology-release",
                        name: "pc-technology-release",
                        component: () =>
                            import("./views/pc/technology/technology-release.vue"),
                        meta: {
                            title: '揭榜挂帅-企业榜单发布页'
                        }
                    },
                    {
                        path: "technologyAwardApply",
                        name: "pc-technology-award-apply",
                        component: () =>
                            import("./views/pc/technology/award-apply.vue"),
                        meta: {
                            title: '揭榜挂帅-奖榜申请页'
                        }
                    },
                    {
                        path: "talent-award-apply",
                        name: "pc-talent-award-apply",
                        component: () =>
                            import("./views/pc/technology/award-apply.vue"),
                        meta: {
                            title: '揭榜挂帅-奖榜申请页'
                        }
                    },
                    {
                        path: "govList",
                        name: "pc-govList",
                        component: () =>
                            import("./views/pc/govList/govList.vue"),
                        meta: {
                            title: '揭榜挂帅-政府榜单页'
                        }
                    },
                    {
                        path: "govList-detail",
                        name: "pc-govList-detail",
                        component: () =>
                            import("./views/pc/govList/govList-detail.vue"),
                        meta: {
                            title: '揭榜挂帅-政府榜单详情页'
                        }
                    },
                    {
                        path: "govListCollect-release",
                        name: "pc-govListCollect-release",
                        component: () =>
                            import("./views/pc/govListCollect/govListCollect-release.vue"),
                        meta: {
                            title: '揭榜挂帅-政府榜单收集页面'
                        }
                    },
                    {
                        path: "news",
                        name: "pc-news",
                        component: () =>
                            import("./views/pc/news/news.vue"),
                        meta: {
                            title: '揭榜挂帅-新闻咨询'
                        }
                    },
                    {
                        path: "news-detail",
                        name: "pc-news-detail",
                        component: () =>
                            import("./views/pc/news/news-detail.vue"),
                        meta: {
                            title: '揭榜挂帅-新闻咨询详情'
                        }
                    },
                    {
                        path: "expert",
                        name: "pc-expert",
                        component: () =>
                            import("./views/pc/expert/talent-expert.vue"),
                        meta: {
                            title: '揭榜挂帅-专家列表'
                        }
                    },
                    {
                        path: "expertDetail",
                        name: "expert-detail",
                        component: () =>
                            import("./views/pc/expert/expert-detail"),
                    },
                    {
                        path: "release-expert",
                        name: "pc-expert-release",
                        component: () =>
                            import("./views/pc/expert/expert.vue"),
                    },
                    {
                        path: "expertPortrait",
                        name: "pc-expert-portrait",
                        component: () =>
                            import("./views/pc/expert/expert-portrait"),
                        meta: {
                            title: '揭榜挂帅-专家头像上传'
                        }
                    },
                    {
                        path: "policy-list-detail",
                        name: "pc-policy-list-detail",
                        component: () =>
                            import("./views/pc/news/policy-detail"),
                        meta: {
                            title: '揭榜挂帅'
                        }
                    },
                    {
                        path: "helpdetail",
                        name: "pc-help-detail",
                        component: () =>
                            import("./views/pc/help/help-detail.vue"),
                        meta: {
                            title: '揭榜挂帅-政府榜单页'
                        }
                    },
                    {
                        path: "alliance",
                        name: "pc-alliance",
                        component: () =>
                            import("./views/pc/alliance/alliance.vue"),
                        meta: {
                            title: '揭榜挂帅-服务联盟'
                        }
                    },
                    {
                        path: "insurance",
                        name: "pc-insurance",
                        component: () =>
                            import("./views/pc/finance/insurance.vue"),
                        meta: {
                            title: '揭榜挂帅-保险投保'
                        }
                    },
                    {
                        path: "alliance-detail",
                        name: "pc-alliance-detail",
                        component: () =>
                            import("./views/pc/alliance/alliance-detail.vue"),
                        meta: {
                            title: '揭榜挂帅-服务联盟详情'
                        }
                    },
                    {
                        path: "reservation",
                        name: "pc-resource-reservation",
                        component: () =>
                            import("./views/pc/alliance/resource-reservation.vue"),
                        meta: {
                            title: '揭榜挂帅-服务预约'
                        }
                    },
                    {
                        path: "survey",
                        name: "pc-question-survey",
                        component: () =>
                            import("./views/pc/survey/question-survey.vue"),
                        meta: {
                            title: '揭榜挂帅-我来建议'
                        }
                    },
                    {

                        path: "oversea-engineer-release",
                        name: "oversea-engineer-release",
                        component: () =>
                            import("./views/pc/oversea/engineer-release"),
                        meta: {
                            title: '揭榜挂帅-海外工程师发布'
                        }

                    },

                    {
                        path: "release",
                        name: "release-view",
                        component: () =>
                            import("./views/pc/guide/release.vue"),
                    },
                    {
                        path: "unveiling",
                        name: "unveiling-view",
                        component: () =>
                            import("./views/pc/guide/unveiling.vue"),
                    },

                    {
                        path: "center",
                        name: "pc-center",
                        redirect: "center/default-page",
                        component: () =>
                            import("./views/pc/center/center.vue"),
                        children: [{
                            path: "default-page",
                            name: "pc-center-default-page",
                            component: () =>
                                import("./views/pc/center/my-list/defaultPage.vue"),
                            meta: {
                                title: '揭榜挂帅-个人中心'
                            }
                        }, {
                            path: "govList-list",
                            name: "pc-center-govList-list",
                            component: () =>
                                import("./views/pc/center/my-list/govList-list.vue"),
                            meta: {
                                title: '揭榜挂帅-我发布的-政府榜单'
                            }
                        },{
                            path: "oversea-list",
                            name: "pc-center-oversea-list",
                            component: () =>
                                import("./views/pc/center/my-list/oversea-list.vue"),
                            meta: {
                                title: '揭榜挂帅-我发布的-海外工程师'
                            }
                        },
                            {
                                path: "inputShow-list",
                                name: "pc-center-inputShow-list",
                                component: () =>
                                    import("./views/pc/center/my-list/inputShow-list.vue"),
                                meta: {
                                    title: '揭榜挂帅-我发布的-展品列表'
                                }
                            },
                            {
                                path: "exhibition-list",
                                name: "pc-center-exhibition-list",
                                component: () =>
                                    import("./views/pc/center/my-list/exhibition-list.vue"),
                                meta: {
                                    title: '揭榜挂帅-我发布的-展品列表'
                                }
                            },
                            {
                                path: "technology-list",
                                name: "pc-center-technology-list",
                                component: () =>
                                    import("./views/pc/center/my-list/technology-list.vue"),
                                meta: {
                                    title: '揭榜挂帅-我发布的-企业榜单'
                                }
                            },
                            {
                                path: "talent-list",
                                name: "pc-center-talent-list",
                                component: () =>
                                    import("./views/pc/center/my-list/talent-list.vue"),
                                meta: {
                                    title: '揭榜挂帅-我发布的-成果榜单'
                                }
                            },
                            {
                                path: "expert-list",
                                name: "pc-center-expert-list",
                                component: () =>
                                    import("./views/pc/center/my-list/expert-list.vue"),
                                meta: {
                                    title: '揭榜挂帅-我发布的-专家申请'
                                }
                            },
                            {
                                path: "govList-unveiling",
                                name: "pc-center-govList-unveiling",
                                component: () =>
                                    import("./views/pc/center/my-unveiling/govList-unveiling.vue"),
                                meta: {
                                    title: '揭榜挂帅-我的揭榜-政府榜单'
                                }
                            },
                            {
                                path: "technology-unveiling",
                                name: "pc-center-technology-unveiling",
                                component: () =>
                                    import(
                                        "./views/pc/center/my-unveiling/technology-unveiling.vue"

                                        ),
                                meta: {
                                    title: '揭榜挂帅-我的揭榜-企业榜单'
                                }
                            },
                            {
                                path: "talent-unveiling",
                                name: "pc-center-talent-unveiling",
                                component: () =>
                                    import("./views/pc/center/my-unveiling/talent-unveiling.vue"),
                                meta: {
                                    title: '揭榜挂帅-我的揭榜-成果榜单'
                                }
                            },
                            {
                                path: "govListCollect-release",
                                name: "pc-center-govListCollect-release",
                                component: () =>
                                    import("./views/pc/center/my-list/govListCollect-list.vue"),
                                meta: {
                                    title: '揭榜挂帅-我的推荐-政府榜单'
                                }
                            },
                            {
                                path: "reservation-list",
                                name: "pc-center-reservation-list",
                                component: () =>
                                    import("./views/pc/center/my-list/reservation-list.vue"),
                                meta: {
                                    title: '揭榜挂帅-我预约的服务'
                                }
                            },
                            {
                                path: "insurance-list",
                                name: "pc-center-insurance-list",
                                component: () =>
                                    import("./views/pc/center/my-list/insurance-list.vue"),
                                meta: {
                                    title: '揭榜挂帅-我的保单'
                                }
                            },
                            {
                                path: "loan-list",
                                name: "pc-center-loan-list",
                                component: () =>
                                    import("./views/pc/center/my-list/loan-list.vue"),
                                meta: {
                                    title: '揭榜挂帅-我的科技贷'
                                }
                            },
                            {
                                path: "chat-list",
                                name: "pc-center-chat-list",
                                component: () =>
                                    import("./views/pc/center/my-list/chat-list.vue"),
                                meta: {
                                    title: '揭榜挂帅-我的科技贷'
                                }
                            },
                            {
                                path: "myCollect-list",
                                name: "pc-center-myCollect-list",
                                component: () =>
                                    import("./views/pc/center/my-list/myCollect-list.vue"),
                                meta: {
                                    title: '揭榜挂帅-我的收藏'
                                }
                            },
                            {
                                path: "myMessage-list",
                                name: "pc-center-message-list",
                                component: () =>
                                    import("./views/pc/center/my-list/unread-message-list.vue"),
                                meta: {
                                    title: '揭榜挂帅-榜单推荐'
                                }
                            },
                            {

                                path: "myResult-list",
                                name: "pc-center-result-list",
                                component: () =>
                                    import("./views/pc/center/my-list/result-exam-list.vue"),
                                meta: {
                                    title: '揭榜挂帅-成果评估列表'
                                }

                            },

                        ],
                    },
                ],
        },
        {
            path: "/mb",
            name: "mb-business",
            component: () =>
                import("./views/mobile/business.vue"),
            children: [{
                path: "index",
                name: "mb-index",
                component: MbIndex,
                meta: {
                    title: '揭榜挂帅'
                }
            },
                {
                    path: "talent",
                    name: "mb-talent",
                    component: () =>
                        import("./views/mobile/personnel/resultsList.vue"),
                    meta: {
                        title: '揭榜挂帅'
                    }
                },
                {
                    path: "expertRelease",
                    name: "mb-expert-release",
                    component: () =>
                        import("./views/mobile/expert/expert-release"),
                    meta: {
                        title: '揭榜挂帅'
                    }
                },
                {
                    path: "mimiExpert",
                    name: "mb-expertList",
                    component: () =>
                        import("./views/mobile/myCenter/my-list/mine-expert-list.vue"),
                },
                {
                    path: "talent-detail",
                    name: "mb-talent-detail",
                    component: () =>
                        import("./views/mobile/personnel/resultsList-details.vue"),
                    meta: {
                        title: '揭榜挂帅'
                    }
                },
                {
                    path: "govList-list",
                    name: "mb-govList-list",
                    component: () =>
                        import("./views/mobile/govList/govList.vue"),
                    meta: {
                        title: '揭榜挂帅'
                    }
                },
                {
                    path: "govList-detail",
                    name: "mb-govList-detail",
                    component: () =>
                        import("./views/mobile/govList/govList-details.vue"),
                    meta: {
                        title: '揭榜挂帅'
                    }
                },
                {
                    path: "news-detail",
                    name: "mb-news-detail",
                    component: () =>
                        import("./views/mobile/news/news-detail.vue"),
                    meta: {
                        title: '揭榜挂帅-新闻咨询详情'
                    }
                },
                {
                    path: "policy-detail",
                    name: "mb-policy-detail",
                    component: () =>
                        import("./views/mobile/news/policy-detail.vue"),
                    meta: {
                        title: '揭榜挂帅-新闻咨询详情'
                    }
                },
                {
                    path: "govListCollect-release",
                    name: "mb-govListCollect-release",
                    component: () =>
                        import("./views/mobile/govListCollect/govListCollect-release.vue"),
                    meta: {
                        title: '需求征集'
                    }
                },
                {
                    path: "technology",
                    name: "mb-technology",
                    component: () =>
                        import("./views/mobile/technology/demandList.vue"),
                    meta: {
                        title: '揭榜挂帅',
                        keepAlive: 'true'
                    }
                },
                {
                    path: "technology-detail",
                    name: "mb-technology-detail",
                    component: () =>
                        import("./views/mobile/technology/demandList-details.vue"),
                    meta: {
                        title: '揭榜挂帅'
                    }
                },
                {
                    path: "technology-award-apply",
                    name: "mb-technology-award-apply",
                    component: () =>
                        import("./views/mobile/technology/award-apply.vue"),
                    meta: {
                        title: '揭榜挂帅'
                    }
                },
                {
                    path: "technology-release",
                    name: "mb-technology-release",
                    component: () =>
                        import("./views/mobile/technology/demandList-release.vue"),
                    meta: {
                        title: '揭榜挂帅'
                    }
                },
                {
                    path: "result-exam-release",
                    name: "mb-result-exam-release",
                    component: () =>
                        import("./views/mobile/resultExam/result-exam-release"),
                },

                {
                    path: "pre-registration-release",
                    name: "mb-pre-registration-release",
                    component: () =>
                        import("./views/mobile/pre/pre-registration-release.vue"),
                    meta: {
                        title: '科技类校外培训机构预登记'
                    }
                },
                {
                    path: "pre-registration-release-second",
                    name: "mb-pre-registration-release-second",
                    component: () =>
                        import("./views/mobile/pre/pre-registration-release-second.vue"),
                    meta: {
                        title: '科技类校外培训机构预登记'
                    }
                },
                {
                    path: "talent-release",
                    name: "mb-talent-release",
                    component: () =>
                        import("./views/mobile/personnel/resultsList-release.vue"),
                    meta: {
                        title: '揭榜挂帅'
                    }
                },
                {
                    path: "alliance",
                    name: "mb-alliance",
                    component: () =>
                        import("./views/mobile/news/helpillustrate.vue"),
                    meta: {
                        title: '揭榜挂帅'
                    }
                },
                {
                    path: "reservation",
                    name: "mb-resource-reservation",
                    component: () =>
                        import("./views/mobile/myCenter/my-list/resource-reservation.vue"),
                },
                {
                    path: "oversea-engineer/release",
                    name: "oversea-engineer-release",
                    component: () =>
                        import("./views/mobile/oversea/engineer-release.vue"),
                    meta: {
                        title: '揭榜挂帅'
                    }
                },
                {
                    path: "technology/loan",
                    name: "mb-technology-loan",
                    component: () =>
                        import("./views/mobile/loan/technologyLoan.vue"),
                },
                {
                    path: "technology/acceptance",
                    name: "mb-technology-acceptance",
                    component: () =>
                        import("./views/mobile/loan/acceptance.vue"),
                },
                {
                    path: "technology/contract",
                    name: "mb-technology-contract",
                    component: () =>
                        import("./views/mobile/myCenter/my-list/technology-contract.vue"),
                },
                {
                    path: "technology/checkList/apply",
                    name: "mb-technology-check-list-apply",
                    component: () =>
                        import("./views/mobile/myCenter/check-list/checkListApply.vue"),
                },
                {
                    path: "technology/checkList/confirm",
                    name: "mb-technology-check-list-confirm",
                    component: () =>
                        import("./views/mobile/myCenter/check-list/checkListConfirm.vue"),
                },
                {
                    path: "publisher/evaluate",
                    name: "mb-publisher-evaluate",
                    component: () =>
                        import("./views/mobile/myCenter/evaluate/publisherEvaluate.vue"),
                },
                {
                    path: "unveiling/evaluate",
                    name: "mb-unveiling-evaluate",
                    component: () =>
                        import("./views/mobile/myCenter/evaluate/unveilingEvaluate.vue"),
                },
                {
                    path: "myCenter",
                    name: "mb-my-center",
                    component: () =>
                        import("./views/mobile/myCenter/mini.vue"),
                    meta: {
                        title: '揭榜挂帅'
                    }
                },
                {
                    path: "minidemand",
                    name: "mb-minidemand",
                    component: () =>
                        import("./views/mobile/myCenter/my-list/mini-demand.vue"),
                },
                {
                    path: "myGovListCollect",
                    name: "myGovListCollect",
                    component: () =>
                        import("./views/mobile/myCenter/my-list/mini-gov-collect.vue"),
                },
                {
                    path: "myResultExam",
                    name: "myResultExam",
                    component: () =>
                        import("./views/mobile/myCenter/my-list/mine-result-exam.vue"),
                },
                {
                    path: "minidemandDetail",
                    name: "mb-minidemandDetail",
                    component: () =>
                        import("./views/mobile/personnel/mini-demand-detail.vue"),
                },

                {
                    path: "mini-jiebang-result",
                    name: "mb-mini-jiebang-result",
                    component: () =>
                        import("./views/mobile/myCenter/my-unveiling/mini-jiebang-result.vue"),
                },
                {
                    path: "talentList",
                    name: "mb-talentList",
                    component: () =>
                        import("./views/mobile/myCenter/my-list/mine-talent-list.vue"),
                },
                {
                    path: "talentDetail",
                    name: "mb-talentDetail",
                    component: () =>
                        import("./views/mobile/personnel/mine-talent-detail.vue"),
                },
                {
                    path: "technology-unveiling-mb",
                    name: "mb-mini-demand-unveiling",
                    component: () =>
                        import("./views/mobile/myCenter/my-unveiling/technology-unveiling-mb.vue"),
                },
                {
                    path: "reservationList",
                    name: "mine-reservation-list",
                    component: () =>
                        import("./views/mobile/myCenter/my-list/mine-reservation-list"),
                }, {
                    path: "loanList",
                    name: "mine-loan-list",
                    component: () =>
                        import("./views/mobile/myCenter/my-list/mine-loan-list"),
                }, {
                    path: "insuranceList",
                    name: "mine-insurance-list",
                    component: () =>
                        import("./views/mobile/myCenter/my-list/mine-insurance-list"),
                }, {
                    path: "insuranceRelease",
                    name: "mb-insurance-release",
                    component: () =>
                        import("./views/mobile/finance/insurance-release"),
                }, {
                    path: "miniMyCollect",
                    name: "mine-myCollect-list",
                    component: () =>
                        import("./views/mobile/myCenter/my-list/mine-myCollect-list.vue"),
                    meta: {
                        title: '揭榜挂帅'
                    }
                }, {
                    path: "miniMyCollect",
                    name: "mine-myCollect-list",
                    component: () =>
                        import("./views/mobile/myCenter/my-list/mine-myCollect-list.vue"),
                    meta: {
                        title: '揭榜挂帅'
                    }
                }

            ],
        },
        {
            path: "/mb/pdfPreview",
            name: "mb-pdfPreview",
            component: () =>
                import("./views/mobile/pdfPreview.vue"),
            meta: {
                title: 'pdf预览'
            }
        },
        {
            path: "/mb/expert-esign",
            name: "mb-expert-esign",
            component: () =>
                import("./views/mobile/expertEsgin/index.vue"),
            meta: {
                title: '专家手写签名',
                loginRequire: false
            }
        },
    ],
});
