import request from "@/utils/request";

//查询政府榜单列表
export function searchAll(query) {
  return request({
    url: "/commander/es/search/all/index",
    method: "get",
    params: query,
  });
}

