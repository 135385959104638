<template>
  <div>
    <div class="szjjjbgs-main" v-if="showZw">
      <div class="szjjjbgs-nav">
        <div class="szjjjbgs-module">
          <div class="szjjnav-left">
            <i class="app-logo"></i>
            <i class="app-namne">数字经济综合应用</i>
          </div>
          <div class="szjjnav-right">
            <!--未登录-->
            <span @click="login" v-if="!username" class="loginInBtn">登 录</span>
            <!--已登录-->
            <div class="app-login" v-if="username">
              <i class="app-login-icon"></i>
              您好，{{ username }}
            </div>
          </div>
        </div>
      </div>
      <div class="szjjjbgs-pagetitle">
        <div class="manbox-name">揭榜挂帅</div>
        <div class="manbox-crumbs">
          <div class="crumbs_list">
            首页
            <i>></i>五金（电动工具）
            <i>></i>
            <em>揭榜挂帅</em>
          </div>
          <a @click="turnParent">
            <div class="backBtn">返回上级</div>
          </a>
        </div>
      </div>
    </div>
    <div class="munk-warp" v-else>
      <div class="munk-top-info">
        <div class="munk-top">
          <a class="fl">{{$t('common.welcome')}}！</a>
          <div class="jbgs-mess fr" v-if="headerFlag != 1 && username">
            <div class="jb-messages" @click="trigMessageBox">
              <span v-if="unreadCount > 0" class="num">{{unreadCount}}</span>
            </div>
          </div>
          <div class="jbgs-messselect fr">
            <el-select v-model="language" placeholder="请选择" @change="changeLanguage">
              <el-option
                v-for="item in languageOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </div>
          <span v-if="username" class="fr">
            <a href="javascript:;">{{ username }}</a>
            <div
              style="width:30px;height:auto;padding-left: 15px;font-size: 18px; color: #fff; position: relative; top: 2px;"
              class="el-icon-message"
              @click="pushMessageBox"
            ></div>

            <a
              style="width:30px;height:auto;padding-left: 15px"
              href="javascript:;"
              @click="logout"
            >{{$t('common.logout')}}</a>
          </span>
          <span v-else class="fr">
            <a href="javascript:;" @click="login">用户登录</a>
          </span>
          <div class="benseach-hdbox fr">
            <div class="benhxbox-laywe" @click="jump('pc-search')">
              <div class="benlay-text">榜单与资讯信息</div>
            </div>
            <div class="benhxbox-posten" v-if="openSearch">
              <div class="posten-title">
                <div class="enle-title">榜单与资讯信息</div>
                <div class="enle-close" @click="openSearch = false"></div>
              </div>
              <div class="posten-seach">
                <input
                  class="poteach-input"
                  type="text"
                  v-model="param.searchKey"
                  placeholder="请输入关键字"
                />
                <div class="poteach-btn" :style="{color : color}" @click="search">搜索</div>
              </div>
              <div class="posten-info">
                <div class="posten-conn" v-for="item in searchList" :key="item.id">
                  <div class="postenino-list" @click="turnToDetail(item.type,item.id)">
                    <div class="poinfst-h2">
                      <div class="poinname" v-html="item.title"></div>
                      <div class="icon-qybd">{{item.type | typeFilter}}</div>
                    </div>
                    <div class="poinfst-ptext" v-html="item.description"></div>
                  </div>
                </div>
                <div class="posten-page">
                  <div class="pagebar-boxs nology-boxs">
                    <el-pagination
                      background
                      @size-change="handleSizeChange"
                      @current-change="handleCurrentChange"
                      :current-page="param.page"
                      :page-sizes="[3, 5]"
                      :page-size="param.pageSize"
                      :pagerCount="5"
                      layout="total, sizes, prev, pager, next, jumper"
                      :total="total"
                    ></el-pagination>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="munk-nav-info">
        <div class="munk-nav-conn">
          <div class="logo-nav fl">
            <img v-if="needChangeImage == true" src="@public/pc/images/jiebanglogo-02.png" />
            <img v-else src="@public/pc/images/jiebanglogo01.png" style="margin-top: -15px"/>
          </div>
          <div class="nav-iteams fl">
            <ul>
              <li :class="{ active: headerFlag === 1 }">
                <a href="javascript:;" @click="jump('pc-index')">{{$t('common.home-big')}}</a>
              </li>
              <li :class="{ active: headerFlag === 8 }">
                <a
                  href="javascript:;"
                  @click="jump('pc-govList')"
                  :content="$t('common.govList')"
                  v-tippy="{ arrow : true, arrowType : 'round',animation : 'scale' }"
                >{{$t('common.govList-big')}}</a>
              </li>
              <li
                :class="{ active: headerFlag === 2 }"
                :content="$t('common.entList')"
                v-tippy="{ arrow : true, arrowType : 'round',animation : 'scale' }"
              >
                <a href="javascript:;" @click="jump('pc-technology')">{{$t('common.entList-big')}}</a>
              </li>
              <li
                :class="{ active: headerFlag === 3 }"
                :content="$t('common.achList')"
                v-tippy="{ arrow : true, arrowType : 'round',animation : 'scale' }"
              >
                <a href="javascript:;" @click="jump('pc-talent')">{{$t('common.achList-big')}}</a>
              </li>
              <li
                :class="{ active: headerFlag === 4 }"
                :content="$t('common.talentExpert')"
                v-tippy="{ arrow : true, arrowType : 'round',animation : 'scale' }"
              >
                <a href="javascript:;" @click="jump('pc-expert')">{{$t('common.talentExpert-big')}}</a>
              </li>
              <li
                :class="{ active: headerFlag === 5 }"
                :content="$t('common.serviceAlliance')"
                v-tippy="{ arrow : true, arrowType : 'round',animation : 'scale' }"
              >
                <a
                  href="javascript:;"
                  @click="jump('pc-alliance')"
                >{{$t('common.serviceAlliance-big')}}</a>
              </li>
              <li :class="{ active: headerFlag === 6 }">
                <a href="javascript:;" @click="jump('pc-news')">{{$t('common.news-big')}}</a>
              </li>
              <li
                :class="{ active: headerFlag === 7 }"
                v-if="username"
                :content="$t('common.personal')"
                v-tippy="{ arrow : true, arrowType : 'round',animation : 'scale' }"
              >
                <a href="javascript:;" @click="jump('pc-center')">{{$t('common.personal-big')}}</a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <message-box></message-box>
    <MessagePushVue></MessagePushVue>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import { login, logout } from "@/api/user";
import MessageBox from "@components/pc/messageBox";
import MessagePushVue from "@components/pc/messagePush.vue";
import { searchAll } from "@/api/all";
export default {
  name: "theHeader",
  components: { MessageBox, MessagePushVue },
  data: function() {
    return {
      languageOptions: [
        {
          value: "zh",
          label: "中文"
        },
        {
          value: "en",
          label: "英文"
        }
      ],
      language: this.$store.state.language,
      searchKey: "",
      openSearch: false,
      searchList: [],
      param: {
        page: 1,
        pageSize: 3
      },
      total: 0,
      color: "#f4f4f4"
    };
  },
  props: {
    needChangeImage: Boolean,
    lang: {
      type: String,
      default: "zh"
    }
  },
  computed: {
    username() {
      return this.$store.state.username;
    },
    headerFlag() {
      return this.$store.state.headerFlag;
    },
    showZw() {
      return this.$store.state.showZw;
    },
    unreadCount() {
      return this.$store.state.unreadCount;
    }
  },
  watch: {
    lang(newValue, oldValue) {
      if (newValue) {
        this.language = newValue;
      }
    },
    "param.searchKey": function(newValue, oldValue) {
      let str = newValue.trim();
      if (str) {
        this.color = "#1a65fb";
      } else {
        this.color = "#f4f4f4";
      }
    }
  },
  methods: {
    ...mapActions(["saveHeaderFlag", "saveToken", "saveLanguage"]),
    //路由切换
    jump(routerName) {
      let _this = this;
      _this.$router.push({
        name: routerName
      });
    },
    login() {
      let _this = this;
      //若是开发环境
      if (process.env.NODE_ENV == "development") {
        //测试环境登录
        login({
          userName: "王海伟"
        }).then(res => {
          let redirectUrl = res.redirectUrl;
          if (redirectUrl) {
            window.location.href = redirectUrl;
          } else {
            //存储token
            localStorage.setItem("userToken", res.token);
            _this.$store.dispatch("saveUserName", res.loginUser.username);
            _this.$store.dispatch("saveToken", res.token);
            // _this.$router.go(0);
          }
        });
      } else {
        //弹出pc登录弹窗
        _this.$login.install({ fromPath: _this.$route.fullPath });
      }
    },
    logout() {
      let _this = this;
      //退出登录
      logout().then(res => {
        //退出登录后台返回成功
        if (res) {
          //清除token
          localStorage.removeItem("userToken");
          _this.$store.dispatch("saveUserName", "");
          _this.$store.dispatch("saveToken", "");
          let fullPath = _this.$route.fullPath;
          if (fullPath.indexOf("code=") != -1) {
            let newPath = fullPath.split("code=")[0];
            _this.$router.replace(newPath);
          } else {
            //页面刷新
            _this.$router.go(0);
          }
        }
      });
    },
    turnParent() {
      let routeName = this.$route.name;
      if (routeName == "pc-technology") {
        window.location.href =
          "https://detest.jxt.zj.gov.cn/web-company-pc-v1/index.html#/view/card?type=%E4%BA%94%E9%87%91%EF%BC%88%E7%94%B5%E5%8A%A8%E5%B7%A5%E5%85%B7%EF%BC%89&industryId=144&firstIndustryName=%E9%AB%98%E7%AB%AF%E8%A3%85%E5%A4%87";
      } else {
        window.location.href =
          "https://jbgs.kjj.jinhua.gov.cn/pc/technology?showZw=true";
      }
    },
    trigMessageBox() {
      this.$store.state.showMessageBox = !this.$store.state.showMessageBox;
    },
    pushMessageBox() {
      this.$store.state.showMessagePush = !this.$store.state.showMessagePush;
    },
    changeLanguage(value) {
      this.$store.dispatch("saveLanguage", value);
      this.$emit("updateLanguage", value);
      this.$i18n.locale = this.language;
    },
    search() {
      let _this = this;
      let str = _this.param.searchKey.trim();
      if (!str) {
        return;
      }
      _this.color = "";
      _this.param.page = 1;
      _this.query();
    },
    query() {
      searchAll(this.param).then(res => {
        this.searchList = res.records;
        this.total = res.total;
      });
    },
    //每页数量调整
    handleSizeChange(val) {
      this.param.pageSize = val;
      this.query();
    },
    //当前页值修改
    handleCurrentChange(val) {
      this.param.page = val;
      this.query();
    },
    turnToDetail(type, id) {
      if (type === "gov") {
        this.$router.push({
          name: "pc-govList-detail",
          query: { id: id }
        });
      } else if (type === "technology") {
        this.$router.push({
          name: "pc-technology-detail",
          query: { id: id }
        });
      } else if (type === "alliance") {
        this.$router.push({
          name: "pc-alliance-detail",
          query: { id: id }
        });
      } else if (type === "news") {
        this.$router.push({
          name: "pc-news-detail",
          query: { id: id }
        });
      } else if (type === "policy") {
        this.$router.push({
          name: "pc-policy-list-detail",
          query: { id: id }
        });
      } else if (type === "talent") {
        this.$router.push({
          name: "pc-talent-detail",
          query: { id: id }
        });
      } else if (type === "expert") {
        this.$router.push({
          name: "expert-detail",
          query: { id: id }
        });
      }
      this.openSearch = false;
    }
  },
  filters: {
    typeFilter(type) {
      if (type === "gov") {
        return "政府榜单";
      } else if (type === "technology") {
        return "企业榜单";
      } else if (type === "alliance") {
        return "服务联盟";
      } else if (type === "news") {
        return "新闻资讯";
      } else if (type === "policy") {
        return "人才政策";
      } else if (type === "talent") {
        return "成果榜单";
      } else if (type === "expert") {
        return "专家榜单";
      }
    }
  }
};
</script>

<style>
.tippy-tooltip {
  font-size: 18px;
  padding: 0.15rem;
}
</style>
<style lang="less" scoped>
.pagebar-boxs.nology-boxs .el-pagination .el-select .el-input .el-input__inner {
  width: 75px;
}

.pagebar-boxs.nology-boxs .el-pagination__editor.el-input .el-input__inner {
  width: 30px;
}
.jbgs-messselect {
  height: auto;
  overflow: hidden;
  display: block;
  width: 100px;
  margin-left: 30px;

  .el-select {
    display: flex;
    align-items: center;
  }
  /deep/.el-input--suffix {
    height: 30px;
    display: flex;
    align-items: center;
  }
  /deep/.el-input__suffix {
    height: 30px;
    display: flex;
    align-items: center;
  }
  /deep/.el-input__inner {
    height: 30px;
    line-height: 30px;
    padding-right: 0;
    background: rgba(0, 0, 0, 0.2);
    color: #fff;
    border-radius: 2px;
    border: none;
  }
  /deep/.el-select /deep/.el-input /deep/.el-select__caret {
    color: #fff;
  }
}
</style>
