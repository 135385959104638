import request from "@/utils/request";

//默认展示帮助助手
export function queryRecommendHelps(query) {
  return request({
    url: "/commander/help/recommendHelp",
    method: "get",
    params: query,
  });
}

//模糊匹配帮助助手
export function getHelpInfo(query) {
  return request({
    url: "/commander/help/getHelp",
    method: "get",
    params: query,
  });
}

//帮助助手详情
export function queryDetail(query) {
  return request({
    url: "/commander/help/detail",
    method: "get",
    params: query,
  });
}


