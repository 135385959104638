import request from "@/utils/request";

//查询过滤条件字典
export function queryFilterConditions(query) {
  return request({
    url: "/common/dict/filter/conditions",
    method: "get",
    params: query,
  });
}

//根据customType查询字典集合
export function queryCommonDict(query) {
  return request({
    url: "/common/dict/customType",
    method: "get",
    params: query,
  });
}

//获取大市场管理人员信息
export function marketManagerInfo(query) {
  return request({
    url: "/common/dict/market/manager",
    method: "get",
    params: query,
  });
}

//获取政府榜单管理人员信息
export function govManagerInfo(query) {
  return request({
    url: "/common/dict/gov/manager",
    method: "get",
    params: query,
  });
}
