import request from "@/utils/request";

//查询专家详情
export function queryDetail(query) {
  return request({
    url: "/commander/expertRegister/queryDetail",
    method: "get",
    params: query,
  });
}
//保存专家
export function saveExpert(data) {
  return request({
    url: "/commander/expertRegister/save",
    method: "post",
    data,
  });
}
//获取attachId
export function getExpertAttDataId(){
  return request({
    url: "/commander/expertRegister/attDataId",
    method: "get",
  });
}
//删除附件
export function delAttach(data){
  return request({
    url: "/attachment/deleteForAjax?ids="+data,
    method: "post",
  });
}
//查找专家列表
export function queryCenterList(query){
  return request({
    url: "/commander/expertRegister/queryCenterList",
    method: "get",
    params: query,
  });
}
//查找当前用户是否注册过专家
export function getCurrentExpertId(){
  return request({
    url: "/commander/expertRegister/getCurExpertId",
    method: "get",
  });
}
//查找专家列表
export function queryList(data){
  return request({
    url: "/commander/expertRegister/list",
    method: "post",
    data,
  });
}
//查找专家列表
export function searchList(data){
  return request({
    url: "/commander/expertRegister/search",
    method: "post",
    data,
  });
}


//专家详情
export function getExpertDetail(query) {
  return request({
    url: "/commander/expertRegister/getExpertDetail",
    method: "get",
    params:query
  });
}


export function isRegisteredByMobile(query){
  return request({
    url: "/commander/expertRegister/isRegisteredByMobile",
    method: "get",
    params:query
  });
}

//查询当前人的退回的专家注册
export function queryBackRegister(query){
  return request({
    url: "/commander/expertRegister/backRegister",
    method: "get",
    params:query
  });
}

//查询成果评估专家评审的专家数据
export function getReviewExpert(query){
  return request({
    url: "/commander/expertRegister/getReviewExpert",
    method: "get",
    params: query
  });
}

//专家手写签名上传
export function upSignature(formId, docId, ruid, binaryData) {
  var data = new FormData();
  data.append("uploadify",binaryData.file)
  return request({
    url:"/commander/expertRegister/upSignature?formId=" + formId + "&docId=" + docId +"&ruid="+ruid,
    method:"post",
    data
  })
}
