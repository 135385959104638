<template>
  <div class="fixed-top">
    <div class="am-navbar">
      <div v-if="showHead">
        <div class="top-back" v-if="showReturn">
          <a class="f34 color-fff" @click="onClickLeft()">返回</a>
        </div>
        <h2 class="color-fff f36 fontbold text-center">{{ title }}</h2>

        <div class="logout-conn" v-if="username && showLogout" @click="logout">
          <span>{{$t('common.logout')}}</span>
        </div>
      </div>
      <div class="myhead-left" @click="gotoHome()" v-if="routerName !== 'mb-index'">
        <i class="icon-left"></i>
      </div>
    </div>
  </div>
</template>

<script>
import { logout } from "@/api/user";
export default {
  props: ["title"],
  data() {
    return {
      showReturn: false,
      showLogout: false,
      showHead: false
    };
  },
  methods: {
    /**
     * 回退
     */
    onClickLeft() {
      this.$router.go(-1);
    },
    logout() {
      let _this = this;
      //退出登录
      logout().then(res => {
        //退出登录后台返回成功
        if (res) {
          //清除token
          localStorage.removeItem("userToken");
          _this.$store.dispatch("saveUserName", "");
          let fullPath = _this.$route.fullPath;
          if (fullPath.indexOf("code=") != -1) {
            let newPath = fullPath.split("code=")[0];
            _this.$router.replace(newPath);
          } else {
            //页面刷新
            _this.$router.go(0);
          }
        }
      });
    },
    gotoHome() {
      let _this = this;
      _this.$router.push({
        path: "/mb/index"
      });
    }
  },
  watch: {
    $route: {
      handler: function(route) {
        let closeReturn = Tool.getQueryVariable("closeReturn");
        if (closeReturn && closeReturn == "1") {
          this.showReturn = false;
          localStorage.setItem("closeReturn", "1");
        } else {
          let flag = localStorage.getItem("closeReturn");
          if (flag && flag == "1") {
            this.showReturn = false;
          } else {
            this.showReturn = true;
          }
        }
        let routeName = route.name;
        if (
          routeName == "mb-index" ||
          routeName == "mb-technology" ||
          routeName == "mb-talent" ||
          routeName == "mb-my-center" ||
          routeName == "mb-news"
        ) {
          this.showReturn = false;
        }
        if (routeName == "mb-my-center") {
          this.showLogout = true;
        } else {
          this.showLogout = false;
        }

        let closeHead = Tool.getQueryVariable("closeHead");
        if (closeHead && closeHead == "1") {
          this.showHead = false;
          localStorage.setItem("closeHead", "1");
        } else {
          let flag = localStorage.getItem("closeHead");
          if (flag && flag == "1") {
            this.showHead = false;
          } else {
            this.showHead = true;
          }
        }
      },
      immediate: true
    }
  },
  computed: {
    username() {
      return this.$store.state.username;
    },
    routerName() {
      return this.$route.name;
    }
  },
  created() {}
};
</script>

<style lang="less">
.myhead-left {
  width: 0.86rem;
  height: 0.96rem;
  position: absolute;
  left: 0;
  top: 0;

  .icon-left {
    width: 100%;
    height: 100%;
    background: url(~@public/mobile/images/home.png) no-repeat center center;
    background-size: 0.48rem;
    display: block;
  }
}
</style>
