import request from "@/utils/request";

//获取人才首页数据
export function indexData(query) {
  return request({
    url: "/commander/news/queryList",
    method: "get",
    params: query,
  });
}

export function queryDetail(query) {
  return request({
    url: "/commander/news/queryDetail",
    method: "get",
    params: query,
  });
}


//search政策数据
export function searchNewsData(query) {
  return request({
    url: "/commander/news/searchList",
    method: "get",
    params: query,
  });
}
