<template>
  <div>
    <div class="asstant-info">
      <div class="asstant-boxs">
        <img
          v-if="routerName == 'pc-technology'"
          class="zixun"
          src="@public/pc/images/czzn.png"
          alt
        />

        <div class="asstantbox-conn">
          <div class="asstantbox-icon">
            <i class="sjiao-icon"></i>
          </div>
          <div class="asstantbox-inver">
            <div class="asstantbox-list" @click="openFbHelp">发榜、奖榜指南</div>
            <div class="asstantbox-list" @click="openJbHelp">揭榜指南</div>
          </div>
        </div>
      </div>
      <img @click="showHelpAssistant = true" class="zixun" src="@public/pc/images/zixun.png" alt />
      <a href="http://kjj.jinhua.gov.cn/art/2023/2/22/art_1229317784_4063639.html">
        <img class="sheji" src="@public/pc/images/icon/icon-sheji.png" alt />
      </a>
      <img src="@public/pc/images/icon/icon-question.gif" alt @click="openSurvey" />
    </div>
    <div class="wapperBox" v-show="showHelpAssistant" :style="{height: hei + 'px'}">
      <div class="dialog">
        <div class="dialog-lfbox">
          <img class="jqr" src="@public/pc/images/jqr.png" alt />
          <div class="rznBox">
            <div>
              <div style="margin-bottom: 5px;" class="fs20">{{$t('common.jieBangHeaded')}}</div>
              <div class="fs16">{{$t('common.machineService')}}</div>
            </div>
            <div style="cursor: pointer;" @click="showHelpAssistant = false">
              <img src="@public/pc/images/close.png" alt />
            </div>
          </div>
          <div class="ycxBox test">
            <div class="disc mb20">
              <img src="@public/pc/images/p1.png" alt />
              <div class="bac">{{$t('common.whatCanIDoForYou')}}</div>
            </div>
            <div class="disc mb20">
              <img src="@public/pc/images/p1.png" alt />
              <div class="cnxw">
                <div class="cnxwb">
                  <img src="@public/pc/images/cnxw.png" alt />
                </div>
                <div
                  class="cnxwb"
                  v-for="(item,index) in defaultHelpList"
                  :key="index"
                  @click="turnHelp(item.id)"
                >
                  <div>{{item.title}}</div>
                  <div>
                    <img src="@public/pc/images/rights.png" alt />
                  </div>
                </div>
              </div>
            </div>
            <div v-for="(item,index) in helpResults" :key="index">
              <!--用户输入 -->
              <div v-if="item.flag == 1" class="disc mb20 disc2">
                <div class="bac2">{{item.title}}</div>
                <img src="@public/pc/images/p2.png" alt />
              </div>
              <!--后台反馈(无数据) -->
              <div v-if="item.flag == 2" class="disc">
                <img src="@public/pc/images/p1.png" alt />
                <div class="bac">{{item.title}}</div>
              </div>
              <!--后台反馈单数据-->
              <div v-if="item.flag == 3" class="disc mb20">
                <img src="@public/pc/images/p1.png" alt />
                <div class="cnxw">
                  <div class="cnxwb" @click="turnHelp(item.id)">
                    <div>{{item.title}}</div>
                    <div>
                      <img src="@public/pc/images/rights.png" alt />
                    </div>
                  </div>
                </div>
              </div>
              <!--后台反馈多数据-->
              <div v-if="item.flag == 4" class="disc mb20">
                <img src="@public/pc/images/p1.png" alt />
                <div class="cnxw">
                  <div
                    class="cnxwb"
                    v-for="help in item.helps"
                    :key="help.id"
                    @click="turnHelp(help.id)"
                  >
                    <div>{{help.title}}</div>
                    <div>
                      <img src="@public/pc/images/rights.png" alt />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="textBox">
            <textarea
              class="test"
              rows="5"
              cols="20"
              placeholder="请输入您想咨询或办理的业务…"
              v-model="counselMsg"
            ></textarea>
            <div class="fasong">
              <span @click="sendMsg">{{$t('common.send')}}</span>
            </div>
          </div>
        </div>
        <div class="dialog-rgbox">
          <div class="dlogrg-nav">
            <ul>
              <li
                v-for="firstType in firstTypeList"
                :key="firstType.id"
                @click="changeFirstType(2 , firstType.code)"
              >
                <div :class="firstType.remark">
                  <span>{{firstType.value}}</span>
                  <i class="line-ty"></i>
                </div>
              </li>
            </ul>
          </div>
          <div class="dlogrg-conn">
            <div
              class="dlocn-list"
              v-for="secondeType in secondTypeList"
              :key="secondeType.id"
              @click="showDefualtRecommendHelp(3 , secondeType.code)"
            >
              <i :class="secondeType.remark"></i>
              <p class="dlocn-text">{{secondeType.value}}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import { queryRecommendHelps, getHelpInfo } from "@/api/helpAssistant";
import { queryCommonDict } from "@/api/commonDict";
import { login, logout } from "@/api/user";
export default {
  name: "helpAssistantDialog",
  data: function() {
    return {
      showHelpAssistant: false,
      counselMsg: "",
      hei: document.documentElement.clientHeight,
      //默认推荐
      defaultHelpList: [],
      //帮助咨询内容列表
      helpResults: [],
      //大类集合
      firstTypeList: [],
      //小类集合
      secondTypeList: []
    };
  },
  props: {
    headerTitle: String,
    routerName: String
  },
  methods: {
    jump(name) {
      this.$router.push({
        name: name
      });
    },
    openSurvey() {
      let userToken = localStorage.getItem("userToken");
      if (userToken != null) {
        this.jump("pc-question-survey");
      } else {
        //走登录
        this.login();
      }
    },
    login() {
      let _this = this;
      //若是开发环境
      if (process.env.NODE_ENV == "development") {
        //测试环境登录
        login({
          userName: "盛小燕"
        }).then(res => {
          let redirectUrl = res.redirectUrl;
          if (redirectUrl) {
            window.location.href = redirectUrl;
          } else {
            //存储token
            localStorage.setItem("userToken", res.token);
            _this.$store.dispatch("saveUserName", res.loginUser.username);
            // _this.$router.go(0);
          }
        });
      } else {
        //弹出pc登录弹窗
        _this.$login.install({ fromPath: _this.$route.fullPath });
      }
    },
    turnHelp(id) {
      let _this = this;
      _this.$router.push({
        name: "pc-help-detail",
        query: {
          id: id,
          headerTitle: _this.headerTitle,
          routerName: _this.routerName
        }
      });
    },
    initRecommendHelp() {
      queryCommonDict({ customType: "helpAssistType" }).then(res => {
        this.firstTypeList = res;
        if (res.length > 0) {
          this.activeSet(this.firstTypeList[0].code);
          this.querySecondTypeData(res[0].code);
        }
      }),
        this.showDefualtRecommendHelp(1, "");
    },
    changeFirstType(recommendLevel, typeVal) {
      this.showDefualtRecommendHelp(recommendLevel, typeVal);
      this.querySecondTypeData(typeVal);
      this.activeSet(typeVal);
    },
    activeSet(typeVal) {
      this.firstTypeList.forEach(item => {
        if (item.code == typeVal) {
          item.remark = "dlonav-list active";
        } else {
          item.remark = "dlonav-list";
        }
      });
    },
    showDefualtRecommendHelp(recommendLevel, typeVal) {
      queryRecommendHelps({
        recommendLevel: recommendLevel,
        typeVal: typeVal
      }).then(res => {
        this.defaultHelpList = res;
      });
    },
    querySecondTypeData(firstCode) {
      queryCommonDict({ customType: firstCode }).then(res2 => {
        this.secondTypeList = res2;
      });
    },
    sendMsg() {
      let _this = this;
      _this.counselMsg = _this.counselMsg.trim();
      if (_this.counselMsg == "") {
        return;
      }
      let sendObj = {};
      sendObj["title"] = _this.counselMsg;
      sendObj["flag"] = 1;
      _this.helpResults.push(sendObj);
      getHelpInfo({ searchKey: _this.counselMsg }).then(res => {
        let retObj = {};
        if (res.length == 0) {
          retObj["title"] = "抱歉，没有找到相应的帮助信息(。・＿・。)ﾉ~";
          retObj["flag"] = 2;
        } else if (res.length == 1) {
          retObj["title"] = res[0].title;
          retObj["id"] = res[0].id;
          retObj["flag"] = 3;
        } else {
          retObj["flag"] = 4;
          retObj["helps"] = res;
        }
        _this.helpResults.push(retObj);
        _this.counselMsg = "";
      });
    },
    openFbHelp() {
      let _this = this;
      _this.$router.push({
        name: "release-view"
      });
    },
    openJbHelp() {
      let _this = this;
      _this.$router.push({
        name: "unveiling-view"
      });
    }
  },
  watch: {
    showHelpAssistant(value) {
      if (value) {
        this.initRecommendHelp();
      }
    }
  }
};
</script>
<style scoped>
@import "~@public/pc/css/helpAssistant.css";
</style>
