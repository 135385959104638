import request from "@/utils/request";

//获取社交关系
export function findRelation(query) {
    return request({
        url: "/community/chat/findRelation",
        method: "get",
        params: query,
    });
}

//创建社交关系
export function createRelation(query) {
    return request({
        url: "/community/chat/createRelation",
        method: "get",
        params: query,
    });
}

//获取聊天记录
export function queryChatContent(query) {
    return request({
        url: "/community/chat/queryChatContent",
        method: "get",
        params: query,
    });
}

//获取关系列表
export function queryRelationList(query) {
    return request({
        url: "/community/chat/relationList",
        method: "get",
        params: query,
    });
}

//统计未读信息的数量
export function getUnreadCount(){
    return request({
        url: "/community/chat/getUnreadCount",
        method: "get",
    });
}

export function groupChatInfo(){
    return request({
        url: "/community/chat/groupChatInfo",
        method: "get",
    });
}