<template>
  <div class="footer">
    <div class="footer-info">
    <i class="footer-icon"></i>
    <span class="copy">
      指导单位：浙江省科技厅 主办单位：金华市科学技术局 地址：金华市双龙南街801号市政府大楼5楼
      <br />承办单位：金华市科技人才与创新服务中心 主办单位联系方式：0579-82050189
      <br />网站标识码：3307000044 技术支持单位：浙江正联网络科技有限公司：0579-83188657
      <br />建议使用1920*1080分辨率，IE11以上版本浏览器或谷歌浏览器
      <br />
      <span
        style="cursor:pointer"
        @click="targetJump('https://beian.miit.gov.cn/#/Integrated/index')"
      >ICP备案备案编号：浙ICP备16013553号-2</span>
      <span
        @click="targetJump('http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=33010602004556')"
        style="margin-left:10px;cursor:pointer"
      >浙公网安备33071802100399号</span>
    </span>
    <div class="benpl-post">
      <span >
        <img
          @click="link('3307000044')"
          src="https://zfwzgl.www.gov.cn/exposure/images/jiucuo.png?v=3307000044"
        />
      </span>
      <span

        @click="targetJump('https://bszs.conac.cn/sitename?method=show&id=06A07BA5799908E9E053022819AC272C')"
      >
        <img
          src="//zjjcmspublic.oss-cn-hangzhou-zwynet-d01-a.internet.cloud.zj.gov.cn/jcms_files/jcms1/web1/site/script/zjzwfw-2019/red.png"
        />
      </span>
  </div>
  </div>
  </div>
</template>

<script>
export default {
  name: "theFooter",
  methods: {
    targetJump(url) {
      window.open(url, "_blank");
    },
    link(site_code) {
      //获取该站点需要纠错页面的url地址
      var url = this.getCurrUrl();
      //跳转至纠错系统填写页面
      window.open(
        "https://zfwzgl.www.gov.cn/exposure/jiucuo.html?site_code=" +
          site_code +
          "&url=" +
          encodeURIComponent(url)
      );
    },
    //获取该站点需要纠错页面的url地址
    getCurrUrl() {
      var url = "";
      if (parent !== window) {
        try {
          url = window.top.location.href;
        } catch (e) {
          url = window.top.document.referrer;
        }
      }
      if (url.length == 0) url = document.location.href;

      return url;
    }
  }
};
</script>

<style scoped>

.footer-info{
 position: relative;
 width: 1200px;
 margin: 0 auto;
}
.benpl-post{
  position: absolute;
  top: 50px;
  right: 70px;
  height: 70px;
  display: flex;
  align-items: center;
}
.benpl-post span{
  margin-left: 30px;
}
</style>
