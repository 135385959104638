import CryptoJS from 'crypto-js/crypto-js'

/**
 * AES加密 ：字符串 key   返回base64
 */
export function encrypt(word, keyParam) {

    const key = CryptoJS.enc.Utf8.parse(keyParam);
    const iv = CryptoJS.enc.Utf8.parse(keyParam);

    let srcs = CryptoJS.enc.Utf8.parse(word);
    var encrypted = CryptoJS.AES.encrypt(srcs, key, {
        iv: iv,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.ZeroPadding
    });
    return CryptoJS.enc.Base64.stringify(encrypted.ciphertext);

}

/**
 * AES 解密 ：字符串 key  返回base64
 *
 */
export function decrypt(word, keyParam) {
    console.log(word)
    const key = CryptoJS.enc.Utf8.parse(keyParam);
    const iv = CryptoJS.enc.Utf8.parse(keyParam);

    let base64 = CryptoJS.enc.Base64.parse(word);
    let src = CryptoJS.enc.Base64.stringify(base64);

    var decrypt = CryptoJS.AES.decrypt(src, key, {
        iv: iv,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.ZeroPadding
    });

    var decryptedStr = decrypt.toString(CryptoJS.enc.Utf8);
    const result = decryptedStr.toString();
    console.log(result);
    if (isJSON(result)) {
        return JSON.parse(result);
    }
    return result;
}

function isJSON(str) {
    if (typeof str == 'string') {
        try {
            const obj = JSON.parse(str);
            if (typeof obj == 'object' && obj) {
                return true;
            } else {
                return false;
            }
        } catch (e) {
            console.log('error：' + str + '!!!' + e);
            return false;
        }
    }
    console.log('It is not a string!')
}