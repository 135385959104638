<template>
  <div class="warpper" id="warpper-mb">
    <!--头部-->
    <home-header :title="title"></home-header>
    <!--banner-->
    <div class="warp-main pt-97">
      <div class="banner-info">
        <div class="banner-list">
          <swiper class="swiper" ref="bannerSwiper" :options="swiperBannerOption">
            <swiper-slide>
              <img src="@assets/images/banner-bj.jpg" />
            </swiper-slide>
            <swiper-slide v-if="zlbComming == 0">
              <img src="@assets/images/toubao.jpg" @click="turnInsurance" />
            </swiper-slide>
            <swiper-slide v-if="zlbComming == 0">
              <img src="@assets/images/banner-credit01.jpg" @click="turnLoan" />
            </swiper-slide>
            <swiper-slide v-if="zlbComming == 0">
              <img src="@assets/images/banner-credit01.jpg" @click="turnOverSea" />
            </swiper-slide>
            <div class="swiper-pagination" slot="pagination"></div>
          </swiper>
        </div>
      </div>
      <div class="enterbox-info">
        <div class="have-problem fl" @click="jump('mb-technology-release')">我有难题</div>
        <div class="have-solve fr" @click="jump('mb-technology')">我来解决</div>
      </div>
      <div class="latest-recom">
        <div class="latest-title">
          <span class="text-recom fl">{{ $t('common.new') }}</span>
          <em class="cumulative fr">
            {{ $t('common.accumulativeTotal') }}
<!--            <i>-->
<!--              <animate-number :from="cumulative" :to="cumulativeNew" duration="2000" easing="easeOutQuad"-->
<!--                :key="cumulativeNew"></animate-number>-->
<!--            </i>{{ $t('common.entry') }}{{ $t('common.list') }}{{ $t('common.fulfilled') }}-->
            <i>
              <animate-number :from="conquered" :to="conqueredNew" duration="2000" easing="easeOutQuad"
                :key="conqueredNew"></animate-number>
            </i>{{ $t('common.entry') }}{{ $t('common.list') }}
          </em>
        </div>
        <div class="latest-conn">
          <ul>
            <swiper class="swiper" ref="swiper" :options="swiperOption">
              <swiper-slide v-for="recommend in recommendList" :key="recommend.id">
                <li @click="technologyDetail(recommend.id)">
                  <div class="latest-list">
                    <div class="latest-pic">
                      <img v-lazy="{ src: srcUrl + recommend.introduction, error: require('@assets/images/pro_00.png') }" />
                    </div>
                    <div class="latest-bj"></div>
                    <div class="latest-name">{{ recommend.requireName }}</div>
                    <div class="latest-cation text-jd">高地分类：{{ recommend.highland_customType }}</div>
                    <div class="latest-prise text-jd">企业：{{ recommend.companyName }}</div>
                  </div>
                </li>
              </swiper-slide>
              <swiper-slide>
                <li></li>
              </swiper-slide>
              <swiper-slide>
                <li></li>
              </swiper-slide>
            </swiper>
          </ul>
        </div>
      </div>
      <div class="enterbox-info">
        <div class="want-result fl" @click="jump('mb-talent-release')">我有成果</div>
        <div class="have-result fr" @click="jump('mb-talent')">我要成果</div>
      </div>
      <div class="regional-info">
        <div class="regal-title">
          <span :class="{
            category: 'category',
            active: listType === 1 && type === 1,
          }" @click="listClick(1, 1)">共性榜单</span>
          <span :class="{
            category: 'category',
            active: listType === 1 && type === 0,
          }" @click="listClick(1, 0)">{{ $t('common.entList') }}</span>
          <span :class="{ category: 'category', active: listType === 2 }" @click="listClick(2, -1)">成果榜单</span>
          <div class="regal-more fr" @click="moreList">更多>></div>
        </div>
        <div class="regal-conn" v-if="listType === 1">
          <ul>
            <swiper class="swiper" ref="technologySwiper" :options="swiperOption">
              <swiper-slide v-for="technology in technologyList" :key="technology.id">
                <li @click="technologyDetail(technology.id)">
                  <div class="latest-list">
                    <div class="latest-pic">
                      <img v-lazy="{ src: srcUrl + technology.introduction, error: require('@assets/images/pro_00.png') }" />
                    </div>
                    <div class="latest-bj"></div>
                    <div class="latest-name">{{ technology.requireName }}</div>
                    <div class="latest-tips">
                      <span class="tips-li">
                        {{
                          technology.highland_customType
                        }}
                      </span>
                    </div>
                    <div class="latest-qiey text-jd">
                      <i class="icon-qiye"></i>
                      {{ technology.companyName }}
                    </div>
                  </div>
                </li>
              </swiper-slide>
              <swiper-slide>
                <li></li>
              </swiper-slide>
              <swiper-slide>
                <li></li>
              </swiper-slide>
            </swiper>
          </ul>
        </div>
        <div class="regal-conn" v-if="listType === 2">
          <ul>
            <swiper class="swiper" ref="talentSwiper" :options="swiperOption">
              <swiper-slide v-for="talent in talentList" :key="talent.id">
                <li @click="talentDetail(talent.id)">
                  <div class="latest-list">
                    <div class="latest-pic">
                      <img src="@assets/images/pro_cg_00.png" />
                    </div>
                    <div class="latest-bj"></div>
                    <div class="latest-name">{{ talent.requireName }}</div>
                    <div class="latest-tips">
                      <span class="tips-li">
                        {{
                          talent.cooperationMode_customType
                        }}
                      </span>
                    </div>
                    <div class="latest-qiey text-jd">
                      <i class="icon-qiye"></i>
                      {{ talent.unitName }}
                    </div>
                  </div>
                </li>
              </swiper-slide>
              <swiper-slide>
                <li></li>
              </swiper-slide>
              <swiper-slide>
                <li></li>
              </swiper-slide>
            </swiper>
          </ul>
        </div>
      </div>
      <div class="enterbox-info" v-if="zlbComming == 0">
        <div class="have-collect fl" @click="jump('mb-govListCollect-release')">需求征集</div>
        <div class="want-solve fr" @click="jump('mb-govList-list')">我要揭榜</div>
      </div>
      <div class="newsfor-info" v-if="zlbComming == 0">
        <div class="newsfor-titl">
          <span class="text-newsfor fl">{{ $t('common.news-big') }}</span>
        </div>
        <div class="newsfor-conn">
          <ul>
            <li v-for="item in commanderNews" :key="item.id" @click="turnDetail(item.id)">
              <div class="news-pic">
                <img v-lazy="srcUrl + item.attId" alt />
              </div>
              <div class="news-text">
                <div class="news-title-mb text-jd">{{ item.headline }}</div>
                <div class="news-ptext">{{ item.title }}</div>
                <div class="news-time">{{ item.releaseDate }}</div>
              </div>
            </li>
          </ul>
        </div>
      </div>


      <div class="newsfor-info" v-if="zlbComming == 0 && commanderPolicies && commanderPolicies.length > 0">
        <div class="newsfor-titl">
          <span class="text-newsfor fl">{{ $t('common.policy-big') }}</span>
        </div>
        <div class="newsfor-conn">
          <ul>
            <li v-for="item in commanderPolicies" :key="item.id" @click="turnPolicyDetail(item.id)">
              <div class="news-pic">
                <img src="@public/pc/images/pro/policy-default02.jpg">
              </div>
              <div class="news-text">
                <div class="news-title-mb text-jd">{{ item.title }}</div>
                <div class="news-ptext">{{ item.subTitle }}</div>
                <div class="news-time">{{ new Date(item.createTime).toLocaleString() }}</div>
              </div>
            </li>
          </ul>
        </div>
      </div>



      <div class="organizer-info" v-if="zlbComming == 1">
        <p>
          <span>本服务由浙江政务服务网、金华市科技局提供</span>
        </p>
        <p @click="callPhone">
          <span>服务咨询热线</span>
          <span style="color:#076ffc;margin-left:5px">0579-82170708</span>
        </p>
      </div>
      <div class="organizer-info" v-else>
        <p>
          <span>指导单位</span>
          <br />浙江省科技厅
        </p>
        <p>
          <span>主办单位</span>
          <br />金华市科技局
        </p>
        <p>
          <span>承办单位</span>
          <br />金华市科技人才与创新服务中心
        </p>
        <p>
          ICP备案备案编号
          <br />浙ICP备16013553号-2
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import homeHeader from "@/components/mobile/header";
import { mapActions } from "vuex";
import {
  indexStatisticData,
  indexRecommend,
  indexList,
  matchList
} from "@/api/index";
import { queryLoginUserVo } from "@/api/user";
import { indexData } from "@/api/new";
import { indexPolicyData } from "@/api/policy"

export default {
  components: { homeHeader },
  data() {
    return {
      title: "首页",
      cumulative: 0,
      conquered: 0,
      cumulativeNew: 0,
      conqueredNew: 0,
      srcUrl: process.env.VUE_APP_IMAGE_URL + "/attachment/downAtt?attId=",
      commanderNews: [],
      commanderPolicies: [],
      recommendList: [],
      //数据左右滑动效果  swiper
      swiperOption: {
        slidesPerView: 3,
        direction: "horizontal",
        autoplay: {
          delay: 3000,
          stopOnLastSlide: false,
          disableOnInteraction: false
        },
      },
      swiperBannerOption: {
        slidesPerView: 1,
        direction: "horizontal",
        autoplay: {
          delay: 4000,
          stopOnLastSlide: false,
          disableOnInteraction: false
        },
        pagination: {
          el: ".swiper-pagination"
        }
      },
      type: 1,
      listType: 1,
      technologyList: [],
      talentList: [],
      zlbComming: 0
    };
  },
  mounted() {
    let _this = this;
    let code = Tool.getQueryVariable("code");
    console.log("code", code);
    if (code) {
      let uiStyle = Tool.getQueryVariable("uiStyle");
      localStorage.setItem("zlbComming", 1);
      _this.zlbComming = 1;
      if (uiStyle) {
        localStorage.setItem("zlbUiStyle", uiStyle);
        if (uiStyle == "elder") {
          _this.oloRem();
          window.document.getElementsByTagName("body")[0].className =
            "older-version";
        } else {
          _this.initRem();
        }
      }
      //自动登录
      _this.autoLogin(code);
    }
    let zlbComming = localStorage.getItem("zlbComming");
    if (zlbComming && zlbComming == 1) {
      _this.zlbComming = 1;
    }
    _this.queryStatistic();
    _this.queryNewThreeList();
    _this.queryPolicyThreeList();
    _this.queryRecommend();
    _this.queryIndexList();
  },
  methods: {
    ...mapActions(["saveUserName"]),
    autoLogin(code) {
      let _this = this;
      //获取token以及用户信息
      queryLoginUserVo({
        code: code
      }).then(res => {
        if (res) {
          //存储token
          localStorage.setItem("userToken", res.token);
          _this.$store.dispatch("saveToken", res.token);
          _this.$store.dispatch("saveUserName", res.loginUser.username);
        }
      });
    },
    //路由跳转
    jump(routerName) {
      let _this = this;
      if (routerName === "mb-technology-release") {
        let userToken = localStorage.getItem("userToken");
        // if (!userToken) {
        //   return _this.$login.install({ fromPath: "/mb/technology-release" });
        // }
      }
      if (routerName === "mb-talent-release") {
        let userToken = localStorage.getItem("userToken");
        // if (!userToken) {
        //   return _this.$login.install({ fromPath: "/mb/talent-release" });
        // }
      }
      _this.$router.push({
        name: routerName
      });
    },
    //成果榜单详情
    talentDetail(talentId) {
      let _this = this;

      _this.$router.push({
        name: "mb-talent-detail",
        query: { id: talentId }
      });
    },

    //获得首页榜单统计数据
    queryStatistic() {
      let _this = this;
      indexStatisticData({ cityId: "330700" }).then(res => {
        _this.cumulative = _this.cumulativeNew;
        _this.conquered = _this.conqueredNew;
        _this.cumulativeNew = res.cumulative;
        _this.conqueredNew = res.conquered;
      });
    },
    //获取新闻前三条
    queryNewThreeList() {
      let _this = this;
      indexData({
        page: 1,
        pageSize: 3
      }).then(res => {
        console.log(res);
        _this.commanderNews = res.records;
      });
    },
    //获取政策前三条
    queryPolicyThreeList() {
      let _this = this;
      indexPolicyData({
        page: 1,
        pageSize: 3
      }).then(res => {
        console.log(res);
        _this.commanderPolicies = res.records;
      });

    },



    //首页推荐数据
    queryRecommend() {
      let _this = this;
      indexRecommend().then(res => {
        _this.recommendList = res;
      });
    },
    //查询首页榜单
    queryIndexList() {
      let _this = this;
      indexList({
        listType: _this.listType,
        type: _this.type
      }).then(res => {
        if (_this.listType === 1) {
          _this.technologyList = res;
        } else {
          _this.talentList = res;
        }
      });
    },
    //榜单类型切换
    listClick(listType, type) {
      let _this = this;
      _this.listType = listType;
      _this.type = type;
      _this.queryIndexList();
    },
    //更多
    moreList() {
      let _this = this;
      //榜单需求列表
      if (_this.listType === 1) {
        _this.jump("mb-technology");
      } else {
        //成果榜单列表
        _this.jump("mb-talent");
      }
    },
    //榜单详情
    technologyDetail(technologyId) {
      let _this = this;

      _this.$router.push({
        name: "mb-technology-detail",
        query: { id: technologyId }
      });
    },
    //这个是跳的方法 开始

    turnDetail(id) {
      let _this = this;
      _this.$router.push({
        name: "mb-news-detail",
        query: { id: id }
      });
    },
    turnPolicyDetail(id) {
      let _this = this;
      _this.$router.push({
        name: "mb-policy-detail",
        query: { id: id }
      });
    },

    turnLoan() {
      let _this = this;
      let userToken = localStorage.getItem("userToken");
      if (!userToken) {
        return _this.$login.install({ fromPath: "/mb/technology/loan" });
      }
      _this.$router.push({
        name: "mb-technology-loan"
      });
    },
    turnInsurance() {
      let _this = this;
      let userToken = localStorage.getItem("userToken");
      if (!userToken) {
        return _this.$login.install({ fromPath: "/mb/insuranceRelease" });
      }
      _this.$router.push({
        name: "mb-insurance-release"
      });
    },
    //海外工程师
    turnOverSea() {
      let _this = this;
      let userToken = localStorage.getItem("userToken");
      if (!userToken) {
        return _this.$login.install({ fromPath: "/mb/oversea-engineer/release" });
      }
      _this.$router.push({
        path: "oversea-engineer/release"
      });
    },
    initRem() {
      const cale =
        window.screen.availWidth > 750 ? 2 : window.screen.availWidth / 375;
      window.document.documentElement.style.fontSize = `${50 * cale}px`;
    },
    oloRem() {
      const cale =
        window.screen.availWidth > 750 ? 2 : window.screen.availWidth / 375;
      window.document.documentElement.style.fontSize = `${70 * cale}px`;
    },
    callPhone() {
      ZWJSBridge.onReady(function () {
        ZWJSBridge.phoneCall({
          corpId: "0579-82170708"
        })
          .then(result => {
            console.log(result);
          })
          .catch(error => {
            console.log(error);
          });
      });
    }
  }
};
</script>

<style>
.swiper-pagination-bullet {
  opacity: 0.8;
}

.swiper-container {
  --swiper-theme-color: #ff6600;
}
</style>

<style scoped>
.pt-97 {
  padding-top: 0.97rem;
}

.swiper-pagination {
  margin-bottom: 0.3rem;
}
</style>
