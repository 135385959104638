<template>
  <div>
    <div class="notranslate-ly">
      <div class="anskate-title">
        <div v-if="!friendUser.avatar" class="skate-pic">
          <img src="@public/pc/images/messbrd-head01.png" />
        </div>
        <div v-if="friendUser.avatar" class="skate-pic">
          <img
            v-lazy="{src:friendUser.avatar,error:require('@public/pc/images/messbrd-head01.png')}"
            alt
          />
        </div>
        <div class="skate-conn">
          <div class="bbsk-name">揭榜挂帅</div>
          <div class="bbsk-tils">{{friendUser.userName}}</div>
        </div>
        <div class="skate-close" style="cursor:pointer" @click="closeChatBox"></div>
      </div>
      <div class="abskate-info" ref="info" @scroll="onTop">
        <li v-for="item in list.slice().reverse()" :key="item.id">

          <div v-if="friendUser.userId != item.targetUserId" class="skinfo-left" :id="item.id">
            <div v-if="!friendUser.avatar" class="skleft-hd">
              <img src="@public/pc/images/messbrd-head01.png" />
            </div>
            <div v-if="friendUser.avatar" class="skleft-hd">
              <img
                v-lazy="{src:friendUser.avatar,error:require('@public/pc/images/messbrd-head01.png')}"
                alt
              />
            </div>

            <div class="skleft-bern" :class="item.content === '454654654654'?'source':''">
              <!--          文本消息类型-->
              <div v-if="item.contentType=='1'" class="skleft-mess">{{item.content}}</div>

              <!--          图片消息类型-->
              <div v-if="item.contentType=='2'" class="skright-pic">
                <img
                  class="imgbpic"
                  :src="srcUrl + item.contentAttach"
                  @load="resourceOnload(item.id,'image')"
                />
              </div>
              <!--          视频消息类型-->
              <div v-if="item.contentType=='3'" class="skright-video">
                <video
                  class="video"
                  :src="srcUrl + item.contentAttach"
                  controls="controls"
                  @canplay="resourceOnload(item.id,'video')"
                >您的浏览器不支持 video 标签。</video>
              </div>
            </div>
            <div class="skleft-fromurl" v-if="item.fromUrl &&  item.fromUrl !== '{}'">
              信息来源：<span class="source-text" @click="handleJump(JSON.parse(item.fromUrl))">{{JSON.parse(item.fromUrl).title}}</span>
            </div>
          </div>
          <div v-if="friendUser.userId == item.targetUserId" class="skinfo-right" :id="item.id">
            <div class="skright-brmn">
              <div v-if="item.contentType=='1'" class="skright-mess">{{item.content}}</div>
              <!--          图片消息类型-->
              <div v-if="item.contentType=='2'" class="skright-pic">
                <img
                  class="imgbpic"
                  :src="srcUrl + item.contentAttach"
                  @load="resourceOnload(item.id,'image')"
                />
              </div>
              <!--          视频消息类型-->
              <div v-if="item.contentType=='3'" class="skright-video">
                <video
                  class="video"
                  :src="srcUrl + item.contentAttach"
                  controls="controls"
                  @canplay="resourceOnload(item.id,'video')"
                >您的浏览器不支持 video 标签。</video>
              </div>
            </div>
          </div>
        </li>
      </div>
      <div class="abskate-chat">
        <div class="abschat-toolbar">
          <emoji @input="selectedEmoji" :textarea="contentObj"></emoji>
          <!--        <div class="atbar-icon icon-smlie"></div>-->
          <!--关于图片上传-->
          <el-upload
             name="uploadify"
            :show-file-list="false"
            :on-success="fileUploadSuccess"
            :before-upload="beforePictureUpload"
            :on-progress="uploadPictureProgress"
            accept="image/png, image/gif, image/jpg, image/jpeg"
            :headers="{ 'X-Access-Token': userToken() }"
            :action="actionUrl + randomNum"
          >
            <div class="atbar-icon icon-uppic" type="primary"></div>
          </el-upload>
          <el-upload
            :show-file-list="false"
            :on-success="fileUploadSuccess"
            :before-upload="beforeVideoUpload"
            :on-progress="uploadVideoProgress"
            accept="video/mp4, video/ogg, video/webm"
            :headers="{ 'X-Access-Token': userToken() }"
            :action="actionUrl + randomNum"
            name="uploadify"
          >
            <div class="atbar-icon icon-upvideo" type="primary"></div>
          </el-upload>
        </div>

        <el-progress v-if="progressFlag" style="margin-top: -60px" :percentage="loadProgress"></el-progress>
        <div class="abschat-pler">
          <textarea
            class="aler-area"
            rows="4"
            placeholder="请输入"
            v-model="contentText"
            id="contentText"
          ></textarea>
        </div>
        <div v-if="contentText ==''" class="abschat-enter">
          <span>发送</span>
        </div>

        <div v-if="contentText!=''" class="abschat-enter">
          <span
            style="background-color: #3aa4ff;color: white;cursor: pointer"
            @click="sendMessage"
          >发送</span>
        </div>
      </div>
    </div>
    <div class="kczl-mask"></div>
  </div>
</template>

<script>
import { Queue,Stack } from "data-struct-js";
import { queryChatContent } from "@/api/community";
import emoji from "@components/pc/emoji";
export default {
  name: "chatBox",
  props: {
    uniId: String,
    friendUser: Object,
  },
  components: {
    emoji
  },

  watch: {
    uniId: function(item) {
      this.queryContent();
      this.init();
      this.registryHook();
    }
  },

  data: function() {
    return {
      messageQueue: Queue,
      //聊天记录
      list: [],
      //分页相关
      pageSize: 20,
      pageNum: 1,
      totalData: 0,
      contentText: "",
      srcUrl: process.env.VUE_APP_IMAGE_URL + "/attachment/downAtt?attId=",
      actionUrl: process.env.VUE_APP_IMAGE_URL + "/attachment/upload?formId=tb_chat&docId=",
      defaultHeight: 16,
      defaultVideoHeight: 166,
      showEmoji: true,
      contentObj: null,
      progressFlag: false,
      loadProgress: 0,
      randomNum:"",
      fromUrl: null
    };
  },

  mounted: function() {
    this.contentObj = document.getElementById("contentText");
    this.queryContent();
    this.init();
    if (this.uniId) {
      this.registryHook();
    }
    this.randomNum = new Date().toLocaleString();
  },

  methods: {
    selectedEmoji(args) {
      console.log(args);
      this.contentText = args;
    },
    userToken() {
      return localStorage.getItem("userToken");
    },
    init() {
      this.messageQueue = window.messageMapper[this.uniId];
      this.messageQueue = new Queue();
      window.messageMapper[this.uniId] = this.messageQueue;
    },
    handleJump(item){
      let routerName = item.router
      let id = item.id
      this.$router.push({
        name: routerName,
        query: {
          id: id
        }
      })
    },
    onTop() {
      if (this.$refs.info.scrollTop <= 0) {
        if (this.list.length < this.totalData) {
          this.queryContent();
        }
      }
    },
    resourceOnload(id, type) {
      if (this.pageNum == 2) {
        if (type === "image") {
          this.$refs.info.scrollTop +=
            document.getElementById(id).offsetHeight - this.defaultHeight;
        } else {
          this.$refs.info.scrollTop +=
            document.getElementById(id).offsetHeight - this.defaultVideoHeight;
        }
      }
    },
    fileUploadSuccess(file) {
      let that = this;
      let item = file.result;
      let suffix = item.savedFileName.substring(item.savedFileName.indexOf("."));
      let contentType = "4";
      if (
        suffix === ".png" ||
        suffix === ".gif" ||
        suffix === ".jpeg" ||
        suffix === ".jpg"
      ) {
        contentType = "2";
      } else {
        contentType = "3";
      }
      let params = {
        contentAttach: item.id,
        uniId: that.uniId,
        contentType: contentType
      };
      //发送图片
      window.webSocket.send(JSON.stringify(params));
    },

    beforePictureUpload(file) {
      let format = file.type.substr(0, 5);
      let isLt10M = file.size / 1024 / 1024 < 10;
      //检查文件类型
      if ("image" != format) {
        this.$message.error("文件格式错误");
        return false;
      }
      if (!isLt10M) {
        this.$message.error("上传的文件大小暂时不能超过10mb");
        return false;
      }
      return true;
    },
    beforeVideoUpload(file) {
      let format = file.type.substr(0, 5);
      let isLt10M = file.size / 1024 / 1024 < 10;

      //检查文件类型
      if ("video" != format) {
        this.$message.error("文件格式错误");
        return false;
      }
      if (!isLt10M) {
        this.$message.error("上传的文件大小暂时不能超过10mb");
        return false;
      }

      return true;
    },

    closeChatBox() {
      //将钩子还原回初始
      window.messageMapper[this.uniId].enqueue = Object.assign(
        window.messageMapper[this.uniId].oldEnqueue
      );
      this.$emit("closeChat");
    },
    sendMessage() {
      let that = this;
      //发消息 清空消息框
      let params = {
        content: that.contentText,
        uniId: that.uniId,
        contentType: "1"
      };
      if (this.fromUrl && this.fromUrl !== {} ){
        params.fromUrl = JSON.stringify(this.fromUrl);
        this.fromUrl = null
      }

      window.webSocket.send(JSON.stringify(params));
      that.contentText = "";
    },
    onRead(item) {
      let that = this;
      if (that.friendUser.userId != item.targetUserId && !item.isRead) {
        let params = {
          content: that.contentText,
          uniId: that.uniId,
          contentType: "5",
          id: item.id
        };
        window.webSocket.send(JSON.stringify(params));
      }
    },
    //给添加队列的方法挂一个钩子
    registryHook() {
      let that = this;
      //拷贝方法(赋值到全局变量方便还原)
      if (!window.messageMapper[that.uniId].oldEnqueue) {
        window.messageMapper[that.uniId].oldEnqueue = Object.assign(
          window.messageMapper[that.uniId].enqueue
        );
      }
      window.messageMapper[that.uniId].enqueue = function(item) {
        that.totalData += 1;
        window.messageMapper[that.uniId].oldEnqueue(item);
        let data = window.messageMapper[that.uniId].dequeue();
        that.list.unshift(data);
        that.onRead(data);
        //重新计算页数
        that.pageNum = Math.ceil(that.list.length / that.pageSize);
        that.$nextTick(() => {
          let info = that.$refs.info;
          info.scrollTop = info.scrollHeight;
        });
      };
    },
    //获取聊天记录
    queryContent() {
      let _this = this;
      queryChatContent({
        uniId: _this.uniId,
        pageNum: _this.pageNum,
        pageSize: _this.pageSize
      }).then(res => {
        _this.totalData = res.total;
        if (_this.pageNum == 1) {
          res.records.forEach(function(item) {
            _this.list.push(item);
            _this.onRead(item);
          });

          _this.$nextTick(() => {
            let info = _this.$refs.info;
            info.scrollTop = info.scrollHeight;
          });
        } else {
          let beforeOffset = document.getElementById(this.list[0].id).offsetTop;
          let dataOffset = _this.list.length % _this.pageSize;
          let pushArray = res.records.splice((res.records.length - dataOffset) * -1);
          pushArray.forEach(function(item) {
            _this.list.push(item);
            _this.onRead(item);
          });

          _this.$nextTick(() => {
            let afterOffset = document.getElementById(this.list[0].id)
              .offsetTop;
            _this.$refs.info.scrollTop = afterOffset - beforeOffset;
          });
        }
        _this.pageNum = Math.ceil(_this.list.length / _this.pageSize + 1);
      });
    },
    uploadPictureProgress(event, file, fileList) {
      this.progressFlag = true; // 显示进度条
      this.loadProgress = parseInt(event.percent);
      if (this.loadProgress >= 100) {
        this.loadProgress = 100;
        setTimeout(() => {
          this.progressFlag = false;
        }, 500); // 0.5秒后关闭进度条
      }
    },
    uploadVideoProgress(event, file, fileList) {
      this.progressFlag = true; // 显示进度条
      this.loadProgress = parseInt(event.percent);
      if (this.loadProgress >= 100) {
        this.loadProgress = 100;
        setTimeout(() => {
          this.progressFlag = false;
        }, 500); // 0.5秒后关闭进度条
      }
    }
  }
};
</script>
