import {
  extend,
  ValidationObserver,
  ValidationProvider,
  localize,
} from "vee-validate";
import {
  required,
  email,
  min,
  max,
  between,
  numeric,
} from "vee-validate/dist/rules";
import zh_CN from "vee-validate/dist/locale/zh_CN.json";
export default {
  install(Vue) {
    Vue.component("ValidationObserver", ValidationObserver);
    Vue.component("ValidationProvider", ValidationProvider);
    extend("required", required);
    extend("email", email);
    extend("min", min);
    extend("max", max);
    extend("between", between);
    extend("numeric", numeric);
    extend("phone", {
      message: "请输入有效的手机号码",
      validate: (value) =>
        value.length === 11 && /^1[3456789]\d{9}$/.test(value),
    });
    extend("listAmountMax", {
      message: "最大为9999.99，且小数点不超过两位",
      validate: (value) =>
         /^(([1-9]\d{0,3})|0)(\.\d{0,2})?$/.test(value),
    });
    extend("telephone", {
      message: "请输入有效的固定号码",
      validate: (value) =>
        /^\d{3}-\d{7,8}|\d{4}-\d{7,8}$/.test(value) || '无' == value,
    });
    extend("salary", {
      params: ["target"],
      validate(value, { target }) {
        return value * 1000 > target * 1000;
      },
      message: "最高预期薪水要大于{_target_}",
    });
  },
};

localize({
  "zh-CN": {
    names: {
      name: "姓名",
      requireName: "需求名称",
      projectName: "需求名称",
      introduction: "榜单介绍图片",
      specifications: "需求说明",
      moneyDemand1: "资金需求开始区间",
      moneyDemand2: "资金需求结束区间",
      imgAttId: "封面图片",
      industryClass: "行业类型",
      technicalField: "程度级别",
      cooperationMode: "合作方式",
      areaId: "县市区",
      invBudget: "项目总投入",
      tbBudget: "榜单金额",
      tbBudgetMin: "榜单金额最小值",
      tbBudgetMax: "榜单金额最大值",
      companyName: "企业名称",
      contactName: "联系人",
      contactMan: "联系人",
      contactMobile: "联系人电话",
      mobile: "联系人电话",
      unitName: "单位名称",
      personalPosition: "个人职务",
      location: "所在地",
      startTime: "开始时间",
      endTime: "结束时间",
      effectiveTime: "有效期",
      tdIndex: "榜单内容",
      companyIntroduction: "企业简介",
      email: "邮箱",
      password: "密码",
      miniSalary: "预期最低薪资",
      highestSalary: "预期最高薪资",
      birthday: "出生日期",
      sex: "性别",
      credentials: "证件类型",
      credentialsNo: "证件号码",
      technologyProfessional: "技术职称",
      professionalGrade: "职称级别",
      education: "学历",
      degree: "学位",
      unitSocialCreditCode: "单位统一社会信用代码",
      graduateSchool: "毕业院校",
      unitType: "单位性质",
      deptName: "所在学院或部门",
      duty: "职务",
      provinces: "所在省份",
      city: "所在地级市",
      county: "所在县市区",
      bankDeposit: "开户银行",
      bankAccount: "银行账号",
      researchArea: "研究方向",
      academic: "学术成就",
      publishType: "政府榜单发榜类型",
      contractAmount: "合同金额",
      firstPartyName: "甲方名称",
      secondPartyName: "乙方名称",
      appAndEconomic: "产业化应用与经济效益",
      achievement: "标志性成果",
      patentContent: "专利内容",
      talentContent: "成果内容",
      firstContent: "首次内容",
      replaceContent: "替代内容",
      otherContent: "其他内容",
      showLevel: "榜单公开程度",
      highland: "领域类型",
      field: "二级领域类型",
      intentionExpertUnit: "意向合作专家/单位",
      territoryCode: "属地信息",
      organizationName: "机构名称",
      schoolLicenceCode: '办学许可证号',
      registerUnit: '登记单位',
      uniqueCode: '社会统一信用代码/组织结构代码',
      legal: '法人代表',
      contactNum: '联系电话',
      contacts: '联系人',
      telephone: '固定电话',
      schoolAddress: '办学地址',
      businessScope: '经营范围',
      trainingProgram: '培训项目',
      employedNum: '从业人员数',
      otherTrainingProgram: '其他培训项目',
      loanFunds: "贷款金额",
      lastYearSale: "上年销售收入",
      putUnderUnit: '主管单位',
      payPerson: '支付对象',
      payAmount: '支付金额',
      payAccount: '支付账号',
    },
    messages: {
      ...zh_CN.messages,
      required: "请输入{_field_}",
      email: "请输入正确的邮箱格式",
      password: "密码必须是6到18位",
    },
  },
});

localize("zh-CN");
