import axios from "axios";
import { Message } from "element-ui";
import { Toast } from "vant";
import login from "./login";
import { rsaDecode } from "./rsaUtil";
import { decrypt } from "./aesUtil";

// create an axios instance
const service = axios.create({
  withCredentials: true,
  baseURL: "/api",
  timeout: 30000,
});

const isMobile = Tool.isMobile();

/**
 * axios拦截器  前置
 */
service.interceptors.request.use(
  function (config) {
    window.APPLICATION.$loading.show();
    let userToken = localStorage.getItem("userToken");
    if (Tool.isNotEmpty(userToken) && userToken !== "null") {
      config.headers["X-Access-Token"] = userToken;
    }
    config.headers["X-Requested-With"] = "XMLHttpRequest";
    // config.headers['content-type'] = 'application/json;charset=UTF-8'
    return config;
  },
  (error) => { }
);

//后置
service.interceptors.response.use(
  async function (response) {
    window.APPLICATION.$loading.close(); //关闭加载
    let res = response.data;
    if (res.code == 200) {
      if (!res.md5Key) {
        return res.result
      }
      //进行解密
      let resultDecrypt = () => {
        //rsa解密获取aes key
        const aesKey = rsaDecode(res.md5Key);
        console.log(aesKey)
        const decodeData = decrypt(res.result, aesKey);
        res.result = decodeData;
      }
      //进行解密
      resultDecrypt()
      return res.result;
    } else if (res.code == 403) {
      let userToken = localStorage.getItem("userToken");
      //若userToken有值  清除token数据
      if (userToken) {
        localStorage.removeItem("userToken");
      }
      let name = window.APPLICATION.$route.name;
      //需要登录的页面
      if (
        name.indexOf("pc-center") != -1 ||
        name.indexOf("-release") != -1 ||
        name.indexOf("pc-expert") != -1
      ) {
        let fullPath = window.APPLICATION.$route.fullPath;
        //登录弹窗
        login.install({
          fromPath: fullPath,
        });
      } else {
        //不需要登录数据的页面  刷新当前页面
        window.APPLICATION.$router.go(0);
      }
      return Promise.reject(res);
    } else {
      if (isMobile) {
        Toast.fail(res.message);
      } else {
        Message.warning(res.message);
      }
      return Promise.reject(res);
    }
  },
  function axiosRetryInterceptor(error) {
    window.APPLICATION.$loading.close(); //关闭加载
    let config = error.config;
    // 如果错误为请求超时则拒绝请求,提示信息
    if (
      error.code == "ECONNABORTED" &&
      error.message.indexOf("timeout") != -1
    ) {
      if (isMobile) {
        Toast.fail("服务器连接超时");
      } else {
        Message.warning("服务器连接超时");
      }
      return Promise.reject(error);
    }
    let status = error.response.status;
    if (status == "404" && error.message.indexOf("404") != -1) {
      if (isMobile) {
        Toast.fail(error.message + " with:" + config.url);
      } else {
        Message.warning(error.message + " with:" + config.url);
      }

    }
    if (status == "500") {
      if (isMobile) {
        Toast.fail("服务器异常");
      } else {
        Message.warning("服务器异常");
      }

    }
  }
);

export default service;
