import request from "@/utils/request";

//获取人才首页数据
export function indexPolicyData(query) {
    return request({
        url: "/commander/policyList/queryList",
        method: "get",
        params: query,
    });
}


//search政策数据
export function searchPolicyData(query) {
    return request({
        url: "/commander/policyList/searchList",
        method: "get",
        params: query,
    });
}



export function queryPolicyDetail(query) {
    return request({
        url: "/commander/policyList/queryDetail",
        method: "get",
        params: query,
    });
}
