import request from "@/utils/request";

//企业用户登录
export function login(query) {
  return request({
    url: "/web/login",
    method: "get",
    params: query
  });
}

//用户退出登录
export function logout(data) {
  return request({
    url: "/web/logout",
    method: "post",
    data
  });
}

//根据code获取用户vo信息
export function queryLoginUserVo(query) {
  return request({
    url: "/web/query/loginUserVo",
    method: "get",
    params: query
  });
}

//获取当前用户信息
export function userInfo(query) {
  return request({
    url: "/web/current/user/info",
    method: "get",
    params: query
  });
}

//获取当前用户信息
export function userSecret(query) {
  return request({
    url: "/web/current/user/secret",
    method: "get",
    params: query
  });
}

//自主研发
export function independentResearch(query) {
  return request({
    url: "/independent/research/jump/url",
    method: "get",
    params: query
  });
}

//模拟登录  用户名称
export function userNameLogin(query) {
  return request({
    url: "/web/test/login",
    method: "get",
    params: query
  });
}

//统一待办跳转登录
export function zventUnifyDraftAutoLogin(query) {
  return request({
    url: "/web/unifyDraft/login",
    method: "get",
    params: query
  });
}

//判断是否同一用户
export function judgeToggleUser(query) {
  return request({
    url: "/usercenter/user/judgeSameUser",
    method: "get",
    params: query
  });
}

//判断浏览器版本
export function detectOS() {
  let sUserAgent = navigator.userAgent;
  let isWin = navigator.platform == "Win32" || navigator.platform == "Windows";
  let isMac = navigator.platform == "Mac68K" || navigator.platform == "MacPPC" || navigator.platform == "Macintosh" || navigator.platform == "MacIntel";
  if (isMac) {
    return "Mac";
  }
  let isUnix = navigator.platform == "X11" && !isWin && !isMac;
  if (isUnix) {
    return "Unix";
  }
  let isLinux = String(navigator.platform).indexOf("Linux") > -1;
  if (isLinux) {
    return "Linux";
  }
  if (isWin) {
    const isWin2K = sUserAgent.indexOf("Windows NT 5.0") > -1 || sUserAgent.indexOf("Windows 2000") > -1;
    if (isWin2K) {
      return "Win2000";
    }
    const isWinXP = sUserAgent.indexOf("Windows NT 5.1") > -1 || sUserAgent.indexOf("Windows XP") > -1;
    if (isWinXP) {
      return "WinXP";
    }
    const isWin2003 = sUserAgent.indexOf("Windows NT 5.2") > -1 || sUserAgent.indexOf("Windows 2003") > -1;
    if (isWin2003) {
      return "Win2003";
    }
    const isWinVista = sUserAgent.indexOf("Windows NT 6.0") > -1 || sUserAgent.indexOf("Windows Vista") > -1;
    if (isWinVista) {
      return "WinVista";
    }
    const isWin7 = sUserAgent.indexOf("Windows NT 6.1") > -1 || sUserAgent.indexOf("Windows 7") > -1;
    if (isWin7) {
      return "Win7";
    }
  }
  if (sUserAgent.indexOf("Windows NT 10") != -1) {
    return "Win10";
  }
  return "other";
}