<template>
  <div v-if="visited">
    <!---弹窗登录--->
    <div v-if="!isMobile">
      <div class="login-box">
        <div class="usname-box">
          <div class="usname-close" @click="closeLogin"></div>
          <div class="usname-titl">登录方式</div>
          <div class="usname-conn">
            <a class="usename-btn" @click="personLogin">个人登录</a>
            <a class="usename-btn" @click="enterpriseLogin">法人登录</a>
          </div>
          <!-- <div class="usname-other">
            <div class="other-title">
              <span class="er-text">第三方登录</span>
            </div>
            <div class="other-conn">
              <a href="javascript:;" class="weixin"></a>
            </div>
          </div>-->
        </div>
      </div>
      <div class="dialog-bj" style="display: block"></div>
    </div>
    <div v-else>
      <div class="minlogin-box">
        <div class="usname-box">
          <div class="usname-close" style="z-index:1" @click="closeLogin"></div>
          <div class="usname-titl">登录方式</div>
          <div class="usname-conn">
            <a class="usename-btn" @click="personLogin">个人登录</a>
            <a class="usename-btn" @click="enterpriseLogin">法人登录</a>
          </div>
        </div>
      </div>
      <div class="dialog_cover" style="display: block"></div>
    </div>
  </div>
</template>

<script>
import { login } from "@/api/user";
export default {
  name: "pcLoginDialog",
  data() {
    return {
      visited: false,
      fromPath: "",
      isMobile: Tool.isMobile()
    };
  },
  methods: {
    //打开
    show() {
      let _this = this;
      _this.visited = true;
    },
    //关闭
    closeLogin() {
      let _this = this;
      let routePath = window.APPLICATION.$route.path;
      console.log("routePath", routePath);
      // //若是个人中心  返回首页  pc端
      // if (routePath && routePath.indexOf("/pc/") != -1) {
      //   window.APPLICATION.$router.replace({
      //     path: "/pc/index"
      //   });
      // }
      // //手机端
      // if (routePath && routePath.indexOf("/mb/") != -1) {
      //   window.APPLICATION.$router.replace({
      //     path: "/mb/index"
      //   });
      // }
      _this.visited = false;
    },
    // 企业法人登录
    enterpriseLogin() {
      let _this = this;
      let url = _this.fromPath;
      if (url.indexOf("?") != -1) {
        url = url + "&website=jbgsCity";
      } else {
        url = url + "?website=jbgsCity";
      }
      login({
        url: url
      }).then(res => {
        let redirectUrl = res.redirectUrl;
        console.log(redirectUrl);
        if (redirectUrl) {
          window.location.href = redirectUrl;
        } else {
          //存储token
          localStorage.setItem("userToken", res.token);
          window.APPLICATION.$store.dispatch(
            "saveUserName",
            res.loginUser.username
          );
          window.APPLICATION.$router.replace({
            path: _this.fromPath
          });
          _this.visited = false;
        }
      });
    },
    //个人登录
    personLogin() {
      let routePath = window.APPLICATION.$route.path;
      if (routePath.indexOf("mb") != -1) {
        window.location.href =
          "https://puser.zjzwfw.gov.cn/sso/mobile.do?action=oauth&scope=1&servicecode=jhjbgsypt&goto=http://jbgs.kjj.jinhua.gov.cn/api/web/person/login?website=jbgsCity?url=" +
          this.fromPath;
      } else {
        window.location.href =
          "https://puser.zjzwfw.gov.cn/sso/usp.do?action=ssoLogin&servicecode=jhjbgsypt&goto=http://jbgs.kjj.jinhua.gov.cn/api/web/person/login?website=jbgsCity?url=" +
          this.fromPath;
      }
    }
  }
};
</script>
