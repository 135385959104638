const NodeRSA = require('node-rsa');

const publicKey = 'MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQCdAN3cLLC1hT7lDA2O1eyWc92IkhSuwZrTMxOD74Jqv2+qp3Z4T4nIwot2xuSoDxgJONvOn9ahygEI0fKeLUCwUfz9hjgr7EqC78RwJ070pC0H2FQ6sU7sVWTpqxwrt9iShpdtZig74Y+qpCc7xG7XtpxQJAwortAXHRCDCXiMeQIDAQAB';

export function rsaDecode(waitDecodeContent) {
    //这里需要使用
    const publicKeyStr = "-----BEGIN PUBLIC KEY-----"
        + publicKey
        + "-----END PUBLIC KEY-----";
    const key = new NodeRSA(publicKeyStr);
    //如果使用公钥加密后，后台使用私钥解密失败，这里需要设置下对应的 encryptionScheme 值
    key.setOptions({ encryptionScheme: 'pkcs1' });
    //使用公钥解密
    return key.decryptPublic(waitDecodeContent, 'utf8');
}